import SharedService from "@/app/services/shared.service";
import { sharedVariable } from '../../services/sharedData.js';
import LogoutModule from "../custompopup/popupModule.controller.js";
import popupService from "../custompopup/popupModule.service.js";

export default {
    name: "customPopUpComponent",
    components: {
        LogoutModule
    },
    props: ['title'],
    setup() {
        // Access the shared variable from Controller A
        const currentPathVariable = sharedVariable.value;
        // Your component logic here

        return {
            currentPathVariable,
            popupService: popupService,
        }
    },
    data() {
        return {
            isOpen: false,
            userName: '',
            headerLogoStyles: {},
            currentPathVariable: '',
            showPopUpModule: false,
            PopUpModuleStyles: {},
            showDropdown: false,
            showRoleDropdown: false,
            selectedRole: 'admin',
            additionalRoles: [],
            currentRole: {}
        }
    },
    created() {
        const loggedInRole = SharedService.getDataFromBrowser('role');
        if (loggedInRole === null || loggedInRole === undefined) {
            this.$router.push({ path: '/' });
        }
        this.displayUserName();
        this.checkCondition();
        this.roleList();
    },

    mounted() {
        document.addEventListener("click", this.handleClickOutside);

    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);

    },
    methods: {
        roleList() {
            const loggedInRole = SharedService.getDataFromBrowser('role');
            this.additionalRoles = SharedService.getDataFromBrowser('userRoles');
            if (Object.keys(this.currentRole).length === 0) {
                if (this.additionalRoles && this.additionalRoles.length > 0) {
                    for (let role of this.additionalRoles) {
                        if (role.value == loggedInRole) {
                            this.currentRole = role
                        }
                    }
                }
            }
        },
        checkCondition() {
            if (this.currentPathVariable == "/landingPage") {
                this.headerLogoStyles = {
                    padding: " 1em !important"
                }
            } else {
                this.headerLogoStyles = {
                    /* padding: "1em 1em 1em 7em !important" */
                    padding: "1em 1em 1em 1em !important"
                }
            }
        },

        validateAdditionalRole(role) {
            if (role == "frameworkAdmin") {
                return (this.additionalRoles[0] = "Framework Admin");
            }
            else if (role == "assessor") {
                return (this.additionalRoles[0] = "Assessor")
            }
            else if (role == "reviewer") {
                return (this.additionalRoles[0] = "Reviewer")
            }
        },
        async displayUserName() {
            const username = SharedService.getDataFromBrowser('firstName');
            this.userName = username;
        },
        logout() {
            SharedService.removeDataFromBrowser('auth_token');
            SharedService.removeDataFromBrowser('roles');
            this.$router.push("/");
            SharedService.clearData('project.model');
            SharedService.clearData('client.model');
            this.$store.commit('clearHistory');
            popupService.togglePopup();
        },
        logoutSession() {
            popupService.togglePopup();
            popupService.togglePopupMessage("Logout Action", "Are you sure you want to logout?",
                (action) => {
                    if (action === 'yes') {
                        this.logout();
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            );
        },
        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        handleClickOutside(event) {
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.showDropdown = false;
            }

        },
        toggleRoleDropdown() {
            this.showRoleDropdown = !this.showRoleDropdown;
        },

        changeRolePopup(role) {
            popupService.togglePopup();
            popupService.togglePopupMessage("Change Role", "Are you sure want to change the role?",
                (action) => {
                    if (action === 'yes') {
                        this.changeRole(role);
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            );
        },
        changeRole(role) {
            // Handle role change logic here
            SharedService.storeDataInBrowser('role', role.value);
            this.currentRole = role
            this.$router.push({ name: 'LandingPage', query: { role: role.value } });
            this.toggleDropdown();
            popupService.togglePopup();
        },

        rqdIconClick() {
            this.$router.push("/landingPage");
        }


    }
}