<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type" :imageType="toastState.imageType"></ToastComponent>
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0">
        <div class="bg-right"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchClientBox" :style="searchclientList" >
          <div class="d-flex justify-content-start align-items-center mb-3 me-5 breadcrumbsResponsive" :style="breadcrumbStyles">
                <BreadcrumbComponent />
          </div>
          <div class="row mx-auto d-flex justify-content-center align-items-center ms-5 me-1 mt-2 clientList150" style="width: 95%;"> 
            <div class="d-flex justify-content-between align-items-center ms-4 p-0 clientListResponsive" style="width: 92%;">
              <div class="headingTextBoxTwo d-flex align-items-center">Client List</div>
              <div  class="d-flex justify-content-between align-items-center " >
              <div  v-if="clientList.length > 0" class="searchApplication">
              <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText" autocomplete="off" placeholder="Search by ClientName" class="form-control customSearch"  @input="searchByClientName()">
              <span @click="toggleSearch" class="customSearchIcon me-3" ></span>
            </div>
              <select v-if="clientList.length > 0" class="page-selector ms-4" v-model="selectedView" @change="viewTo" :disabled="clientList.length < 10">
                <option selected disabled>view</option>
                <option v-for="view in viewDetails" :key="view" :value="view">{{view}}</option>
              </select>
              </div>
              <button v-if="this.loggedInRole='frameworkAdmin'" type="search" class="btn btn-primary addNewClientButton" v-on:click="addNewClientClick()">
                Create Client
              </button>
            </div>
              <div class="clientListTable mt-3 ms-4 p-0">
              <table class="searchClientListTable">
                <thead>
                  <tr>
                  <th style="border-radius: 10px 0px 0px 0px;" > 
                    <div class="d-flex justify-content-start align-items-center">
                    <span >Client Name</span>
                    <span :class="sortOrder.clientName==='ascending'? 'icon-ascending': sortOrder.clientName==='descending'? 'icon-descending':'icon-common'"  @click="sortClient('clientName')"></span>
                  </div>
                  </th> 
                  <th >
                    <div class="d-flex justify-content-start align-items-center">
                    <span >Industry</span>
                    <span  :class="sortOrder.industry==='ascending'? 'icon-ascending': sortOrder.industry==='descending'? 'icon-descending':'icon-common'"  @click="sortClient('industry')"></span>
                    </div>
                  </th>
                  <th >
                    <div class="d-flex justify-content-start align-items-center">
                      <span >Status</span>
                      <span :class="sortOrder.isActive==='ascending'? 'icon-ascending': sortOrder.isActive==='descending'? 'icon-descending':'icon-common'"  @click="sortClient('isActive')"></span>
                    </div>
                  </th>
                  <th style="border-radius: 0px 10px 0px 0px;">
                    <div class="d-flex justify-content-start align-items-center">
                      <span class="p-0">Action</span>
                    </div>
                  </th>
                  </tr>
                </thead>
                <tbody>

                  <div v-if="showEmptyTableContent" class="d-flex justify-content-center mt-5 mb-5 displayContent" >
                    <span>{{ showEmptyTableContent }}</span>
                  </div>

                  <tr v-for="(client,index) in filteredClientList" :key="index">
                    <td >
                      <span >
                        {{ client.clientName }}
                      </span>
                    </td>
                    <td>
                      <span>
                      {{ client.industry}}
                    </span>
                    </td>
                    <td><span>{{ getStatus(client.isActive) }}</span></td>
                    <td>
                      <div class="d-flex justify-content-start align-items-center">
                        <div class="d-flex justify-content-start align-items-center" :style="spaceDivAssessor">
                      <span  type="search" class="btn projectIcon d-flex justify-content-center align-items-center p-0" v-on:click=" ProjectListClick(client)"  @mouseover="showToolTip(index,'add')" @mouseleave="hideToolTip(index,'add')">
                        <div v-if="showToolTipIndex === index && activeTooltipType==='add'" class="toolTip">
                              <div :class="['tooltiptext' ,isLastRow(index) ? 'up':'down']">
                             Project
                        </div>
                        </div>
                      </span>
                        <span type="search" class="btn editIcon d-flex justify-content-center align-items-center p-0" v-on:click="editClient(client)" @mouseover="showToolTip(index,'edit')" @mouseleave="hideToolTip(index,'edit')">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='edit'" class="toolTip">
                              <div :class="['tooltiptext' ,isLastRow(index) ? 'up':'down']">
                              Edit
                            </div>
                          </div>
                        </span>
                        <span type="search" class="btn deleteIcon d-flex justify-content-center align-items-center p-0" v-on:click="deleteClient(client.id)" @mouseover="showToolTip(index,'delete')" @mouseleave="hideToolTip(index,'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType==='delete'" class="toolTip">
                              <div :class="['tooltiptext' ,isLastRow(index) ? 'up':'down']">
                              Delete
                            </div>
                          </div>
                        </span>
                      </div>
                      </div>
                    </td>
                  </tr>
              </tbody>
              </table>
              </div>
          </div>
          <div v-if="clientList.length > 0" class="d-flex justify-content-end align-items-center mt-3 align-items-center paginationBox">
              <span class="float-right pagination-detail">Jump to</span>
              <span class="me-1 ms-1 seperator"></span>
              <label class="page">Page</label>
              <div class="dropdown-wrapper">
                <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                  <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{page}}</option>
                </select>
              </div>
              <span class="ms-2 me-2 seperator"></span>
              <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{ paginationData.totalRecords }}
            </span>
              </div>
        </div>
      </div>
    </div>
  </div> 
</template>
<script src="./clientList.controller.js"></script>
<style scoped src="./clientList.style.css"></style>  














                    