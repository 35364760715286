import sharedService from '@/app/services/shared.service';
import { sbus } from '@/app/services/sharedData.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../../client/client.service.js';
import projectService from '../project.service';
import CreateProject from './CreateProject.vue';



export default {
    name: "createProject",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CreateProject,
        SideNav,
        ToastComponent
        // ProjectModel,
    },
    setup() {
        return {
            // toastState: toast,
            toastState: toast,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            successToastMsg: "",
            errorToastMsg: "",
            ProjectModel: {
                isActive: "Y"
            },
            projectId: "",
            editProject: false,
            errors: {
                client: "",
                project: "",
                sbu: "",

            },
            sbus,
            breadcrumbStyles: {},
            clientList: [],
            clientName: "",
            saveLater: false,
            CreateProject,
            createProjectResponse: {},
            ClientModel: {},

        };
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))')
        const midScreenMedia = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        const largeScreenMedia = window.matchMedia('((min-width:1441px) and (max-width:1600px))')
        if (midScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "95.5%",
            }
        }
        else if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "94.75%",
            };
        }
        else if (largeScreenMedia.matches){
            this.breadcrumbStyles = {
                width: "96%",
            };
        }
        else {
            this.breadcrumbStyles = {
                width: "96%",
            };
        }

        this.projectId = this.$route.params.projectId;
        this.$store.commit("addToHistory", this.$route);
        if (this.projectId) {
            this.routePath = "/editProject";
            this.editProject = true;
            this.successToastMsg = " Project updated successfully";
            this.errorToastMsg = "Failed to update project";
            this.ProjectModel = sharedService.getData("project.model");
            if (!this.ProjectModel) {
                this.ProjectModel = {};
                this.fetchProject(this.projectId);
            }
        } else {
            this.routePath = "/createProject";
            this.clientId = this.$route.params.clientId;
            this.successToastMsg = " Project created successfully";
            this.errorToastMsg = "Failed to create project";

        }
        this.ClientModel = sharedService.getData("client.model");
        if (!this.ClientModel && this.clientId) {
            this.fetchClientDetails(this.clientId);
        }

    },
    methods: {


        cancelClick() {
            this.$router.push({ name: "projectList", params: { clientId: this.ClientModel.id } });
        },

        clientValidation() {
            if (this.ProjectModel.clientName == undefined) {
                this.errors.client = "Please Select a Client";
            } else {
                this.errors.client = "";
            }
        },
        projectValidation() {
            if (this.ProjectModel.projectName == undefined || this.ProjectModel.projectName == "" || this.ProjectModel.projectName == null) {
                this.errors.project = "Please Enter a Project Name";
            }
            else {
                this.errors.project = "";
                this.ProjectModel.projectName = this.ProjectModel.projectName.charAt(0).toUpperCase() + this.ProjectModel.projectName.slice(1);

            }


        },
        sbuValidation() {
            if (this.ProjectModel.sbu == undefined) {
                this.errors.sbu = "Please select SBU";
            } else {
                this.errors.sbu = "";

            }


        },

        checkTextareaLength() {
            console.log(this.$nextTick)
            let descriptionCharacterLength = this.ProjectModel.projectDescription
            if (descriptionCharacterLength === undefined || descriptionCharacterLength === null || descriptionCharacterLength === "") {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
                this.ProjectModel.projectDescription = this.ProjectModel.projectDescription.charAt(0).toUpperCase() + this.ProjectModel.projectDescription.slice(1);
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.projectDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.projectDescription = ''
                }
            })
        },

        createProjectClick() {
            if (this.ProjectModel.projectName == undefined || this.ProjectModel.projectName == "" || this.ProjectModel.projectName == null) {
                this.projectValidation();
            } else if (this.ProjectModel.sbu == undefined) {
                this.sbuValidation();
            } else {
                this.createProject();
            }
            event.preventDefault();
        },

        async createProject() {
            this.ProjectModel.clientId = this.ClientModel.id;
            try {
                const response = await projectService.createProjectAPI(this.ProjectModel);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.createProjectResponse = response.data.data;
                    this.ProjectModel.id = this.createProjectResponse.id;
                    sharedService.setData(
                        "project.model",
                        this.createProjectResponse
                    );
                    showToast(this.successToastMsg, "success", 'validationsuccess');
                    this.$router.push({ name: "projectList", params: { clientId: this.ClientModel.id } });
                } else {
                    showToast(this.errorToastMsg, "error", 'validationerror');
                }
            }
            catch (error) {
                console.log(error);
                showToast(error.response.data.message, "error", "validationerror");
            }
        },
        async fetchProject(projectId) {
            try {
                const response = await projectService.fetchProjectById(projectId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.ProjectModel = response.data.data;
                    sharedService.setData("project.model", this.ProjectModel);
                    if (!this.ClientModel) {
                        this.ClientModel = {};
                        this.fetchClientDetails(this.ProjectModel.clientId);
                    }
                } else {
                    showToast("Failed to fetch Project Details", "error", "validationerror");
                }
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Project Details", "error", "validationerror");
            }
        },

        async fetchClientDetails(clientId) {
            const response = await clientService.fetchClientById(clientId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.ClientModel = response.data.data;
                sharedService.setData("client.model", this.ClientModel);
            } else {
                showToast("Failed to fetch Client Details", "error", "validationerror");
            }
        },



    }
}