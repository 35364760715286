import { createStore } from 'vuex';
import sharedService from '@/app/services/shared.service';

export default createStore({
    state: {
        historyPaths: sharedService.getDataFromBrowser('historyPaths') || [],
    },
    mutations: {
        addToHistory(state, routeObj) {
            let found = false;
            for (let i = state.historyPaths.length - 1; i >= 0; i--) {
                if (state.historyPaths[i].path === routeObj.path) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                // Simplify the route object to avoid circular references
                const simplifiedRouteObj = {
                    path: routeObj.path,
                    name: routeObj.name || '',
                    meta: routeObj.meta || {}, // Include additional properties if needed
                };

                state.historyPaths.push(simplifiedRouteObj);
                sharedService.storeDataInBrowser('historyPaths', state.historyPaths);
            }
        },
        removeFromHistory(state, path) {
            let routeObjToRemove = null;
            for (let i = state.historyPaths.length - 1; i >= 0; i--) {
                if (state.historyPaths[i].path.startsWith(path)) {
                    routeObjToRemove = state.historyPaths[i];
                    break;
                }
            }
            if (routeObjToRemove) {
                state.historyPaths.splice(state.historyPaths.indexOf(routeObjToRemove), 1);
                sharedService.storeDataInBrowser('historyPaths', state.historyPaths);
            }
        },
        clearHistory(state) {
            state.historyPaths = [];
            sharedService.removeDataFromBrowser('historyPaths');
        },
    },
});
