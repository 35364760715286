import userService from '@/app/login/login.service.js';
import axiosInstance from "@/axios";
import SharedService from "../services/shared.service";
import { showToast } from "../shared/toast/toast.controller.js";

axiosInstance.interceptors.request.use((config) => {
    const token = SharedService.getDataFromBrowser("auth_token");
    //   const enterpriseId = SharedService.getDataFromBrowser("enterpriseId");
    if (token && (config.url != 'api/user/authenticate' && !config.url.startsWith('api/user/refreshToken')) && !config.url.startsWith('api/user/forgotPassword') && !config.url.startsWith('api/user/changePassword')) {
        config.headers.Authorization = `Bearer ${token}`;
        //   config.headers.EnterpriseId = enterpriseId;
    }

    SharedService.setSpinner(true);
    return config;
}, (error) => {
    SharedService.setSpinner(false);
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    SharedService.setSpinner(false);
    return response;
}, async function (error) {
    SharedService.setSpinner(false)
    console.log('Error caught in interceptor--', error);
    if (error.code) {
        if (error.code == 'ERR_NETWORK') {
            showToast('Netwrok Error occured. Please contact administator.', 'error', 'validationerror');
        }
        else if (error.code == 'ECONNABORTED') {
            showToast('Request Timeout. Please contact administator.', 'error', 'validationerror');
        }

       /* else if(error.code=='ERR_BAD_RESPONSE' && error.response.data.message!="Invalid credentials."){
            showToast('Session Timeout. Please Login Again.', 'error', 'validationerror');
            window.location.href ="/";
        }*/   else if (error.code == 'ERR_BAD_RESPONSE' && error.response.data.message.startsWith("JWT expired")
            && error.config.url != 'api/user/refreshToken') {

            const response = await userService.refreshToken(SharedService.getDataFromBrowser("refreshToken"));
            if (response.data.header.responseMessage === "SUCCESS") {
                const accessToken = response.data.data.jwtToken;
                const refreshToken = response.data.data.refreshToken;
                SharedService.storeDataInBrowser('auth_token', accessToken);
                SharedService.storeDataInBrowser('refreshToken', refreshToken);
                //invoke original request
                error.config.headers.Authorization = `Bearer ${accessToken}`;
                return axiosInstance.request(error.config);
            }
        }
        else {
            console.log('Error caught in interceptor--', error);
            showToast('Unexpected Error. Please contact administator.', 'error', 'validationerror');
        }
        return Promise.reject(error);
    }
});


export default axiosInstance;