class statusService {
    constructor() {
        this.models = {};

    }

    getStatus(status) {
        if (status == "Y") {
            return "Active";
        } else if (status == "N") {
            return "InActive";
        } else if (status == "draft") {
            return "Draft";
        } else if (status == "inprogress") {
            return "In Progress";
        } else if (status == "submitted") {
            return "Submitted";
        } else if (status == "completed") {
            return "Completed";
        } else if (status == "approved") {
            return "Approved";
        } else if (status == "rejected") {
            return "Rejected";
        } else if (status == "finalized") {
            return "Finalized";
        }
         else {
            return "Status";
        }


    }


}
export default new statusService();

