import artifactService from '@/app/ArtifactDetails/artifact.service.js';
import ArtifactList from "@/app/ArtifactDetails/ArtifactList.vue";
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import ToastComponent from "@/app/shared/toast/ToastComponent.vue";
import { SweetModal, SweetModalTab } from "sweet-modal-vue-3";
import { reactive, toRaw } from 'vue';
import { paginationService } from '../services/pagination.service.js';
import sharedService from "../services/shared.service.js";
import statusService from '../services/status.service.js';
import popupService from '../shared/custompopup/popupModule.service.js';
import { showToast, toast } from '../shared/toast/toast.controller.js';

export default {
    name: "artifactList",
    components: {
        FooterComponent,
        HeaderComponent,
        ArtifactList,
        SideNav,
        SweetModal,
        SweetModalTab,
        BreadcrumbComponent,
        ToastComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            frameworkList: [],
            assessmentId: '',
            assessentName: '',
            assessmentArtifactList: {},
            AssessmentModel: {},
            ArtifactModel: {},
            frameworkTypes: [],
            frameworkListByType: [],
            saveArtifactResponse: {},
            errors: {
                artifactName: '',
                artifactTypr: '',
                artifactDescription: ''
            },
            page: 10,
            deleteArtifactId: '',
            breadcrumbStyles: {},
            showDisplayMessage: "",
            emptyArtifact: false,
            viewButtonVisible: {},
            showToolTipIndex: null,
            activeTooltipType: null,
            userList: [],
            frameworkEditFlag: false,
            editFrameworkName: ' ',
            readOnlyText: {},
            sortOrder: {
                artifactname: "common",
                artifacttype: "common",
                attachmentExtn: "common",
                version: "common",
                artifactStatus: "common"

            },
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            selectedPage: 1,
            displayedArtifactList: [],
            currentPage: 1,
            rowsPerPage: 10,
            totalPages: 0,
            paginationData: {}
        };
    },
    computed: {
        filteredassessmentArtifactList() {
            return this.displayedArtifactList;

        },
        isBrowseEnabled() {
            return this.ArtifactModel.artifactStatus === "draft";
        }
    },

    created() {
        // this.$store.commit('clearHistory');
        this.breadcrumbStyles = {
            width: '91.5%',
        }
        this.assessmentId = this.$route.params.assessmentId;
        this.AssessmentModel = sharedService.getData('assessment.model');
        if (!this.AssessmentModel) {
            this.fetchAssessment(this.assessmentId);
        }
        //this.fetchArtifactUserList(this.assessmentId);
        this.fetchArtifactList(this.assessmentId);
        this.fetchFrameworkList();
        this.readOnlyText = {
            color: ' #767676; !important',
        }
    },
    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        isLastRow(index) {
            return index === this.filteredassessmentArtifactList.length - 1 && this.filteredassessmentArtifactList.length > 1;
        },
        validateSaveArtifact() {
            if (this.ArtifactModel.artifactName == undefined || this.ArtifactModel.artifactName == ""|| this.ArtifactModel.artifactName == null) {
                this.artifactNameErrorCheck();
            } else if (this.ArtifactModel.artifactType == undefined) {
                this.artifactTypeErrorCheck();
            } else if (this.ArtifactModel.frameworkId == undefined) {
                this.frameworkNameErrorCheck();
            } else if (this.ArtifactModel.version == undefined || this.ArtifactModel.version == "" || this.ArtifactModel.version == null) {
                this.artifactVersionErrorCheck();
            }
            else if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0 && this.frameworkEditFlag) {
                this.selectFile();
            }
            else {
                // this.$router.push("/searchAssessment");
                this.ArtifactModel.assessmentId = this.assessmentId;
                const formData = new FormData();
                if (this.file != undefined) {
                    formData.append('file', this.file, this.file.name);
                    formData.append("artifact", JSON.stringify(this.ArtifactModel))
                    this.saveArtifact(formData);

                }
                else {
                    this.updateArtifact(this.ArtifactModel);
                }

            }
            event.preventDefault();
        },
        artifactNameErrorCheck() {
            if (this.ArtifactModel.artifactName == undefined || this.ArtifactModel.artifactName == "" || this.ArtifactModel.artifactName == null) {
                this.errors.artifactName = 'Please Enter valid Artifact Name';
            } else {
                this.errors.artifactName = '';
                this.ArtifactModel.artifactName = this.ArtifactModel.artifactName.charAt(0).toUpperCase() + this.ArtifactModel.artifactName.slice(1);

            }
        },
        artifactTypeErrorCheck() {
            if (this.ArtifactModel.artifactType == undefined) {
                this.errors.artifactType = 'Please Choose valid Artifact Type';
            } else {
                this.errors.artifactType = '';
                event.preventDefault();
                this.FrameworkModel = [];
                this.frameworkListByType = [];
                for (let framework of toRaw(this.frameworkList)) {
                    if (this.ArtifactModel.artifactType === framework.type &&
                        framework.clients.some(client => client.id === this.AssessmentModel.clientId)) {
                        this.frameworkListByType.push(reactive(framework));
                    }
                }
                if (this.frameworkListByType.length === 0) {
                    showToast('No Frameworks available for the selected type', 'warning', 'validationwarning')
                }
            }

        },
        truncateText(text, limit) {
            if (!text) return '';
            return text.length > limit ? text.substring(0, limit) + '...' : text;
        },
        selectFile() {
            if (!this.ArtifactModel.attachment && this.$refs.file.files.length == 0) {
                this.errors.attachment = 'Please upload valid document';
            } else {
                this.file = this.$refs.file.files[0];
                if (this.file == undefined) {
                    this.errors.attachment = '';
                }
                else if (this.file.size > 3000000) {
                    this.errors.attachment = 'Please upload a document less than 3mb';
                }

                else {
                    // this.errors.attachment = '';
                    const allowedTypes = ['pdf', 'doc', 'docx', 'txt', 'xls', 'xlsx', 'ppt'];
                    const dotIndex = this.file.name.lastIndexOf('.');
                    if (dotIndex !== -1) {
                        const fileExtension = this.file.name.slice(dotIndex + 1).toLowerCase();

                        if (!allowedTypes.includes(fileExtension)) {
                            // showToast('images are not allowed. please upload a valid document.','error','validationError');
                            this.errors.attachment = 'please upload a below format - pdf,doc,docx,txt,xls,xlsx,ppt';
                            this.ArtifactModel.attachment = '';
                            this.ArtifactModel.attachmentExtn = '';
                            return;
                        }
                        this.ArtifactModel.attachment = this.file.name.slice(0, dotIndex);
                        this.ArtifactModel.attachmentExtn = fileExtension;
                        this.errors.attachment = '';
                    } else {
                        this.errors.attachment = 'invalid file format.Please upload a valid document.';
                        this.ArtifactModel.attachment = '';
                        this.ArtifactModel.attachmentExtn = '';
                    }
                }
                // this.ArtifactModel.attachment = this.file.name;
            }

        },
        frameworkNameErrorCheck() {
            if (this.ArtifactModel.frameworkId == undefined) {
                this.errors.artifactFramework = 'Please choose valid framework';
            } else {
                for (let framework of this.frameworkList) {
                    if (framework._id === this.ArtifactModel.frameworkId) {
                        this.ArtifactModel.frameworkActiveStatus = framework.isActive;
                    }
                }
                this.errors.artifactFramework = '';
                this.viewButtonVisible = {
                    visibility: "visible"
                };
            }
        },
        artifactVersionErrorCheck() {
            if (this.ArtifactModel.version == undefined || this.ArtifactModel.version=="" || this.ArtifactModel.version == null) {
                this.errors.version = 'Please Enter valid Artifact version';
            } else {
                this.errors.version = '';
            }

        },
        checkTextareaLength() {
            let descriptionCharacterLength = this.ArtifactModel.artifactDescription
            if (descriptionCharacterLength === undefined || descriptionCharacterLength === null||descriptionCharacterLength === "") {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
                this.ArtifactModel.artifactDescription = this.ArtifactModel.artifactDescription.charAt(0).toUpperCase() + this.ArtifactModel.artifactDescription.slice(1);
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 100) {
                    this.errors.artifactDescription = 'Please Enter valid Description less than 100 characters';
                } else {
                    this.errors.artifactDescription = ''
                }
            })
        },
        assessmentList() {
            if (this.assessmentId) {
                this.$router.push({ name: "editAssessment", params: { assessmentId: this.AssessmentModel.assessmentId } });
            } else {
                showToast("Assessment is not created", 'warning', 'validationwarning');
            }
        },
        assessmentUserList() {
            if (this.assessmentId && this.emptyArtifact) {
                this.$router.push({ name: "assignUserList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast("Artifact is not created", 'warning', 'validationwarning');
            }

        },
        addArtifact() {
            this.ArtifactModel = {
                artifactStatus: 'draft'
            };
            this.file = undefined;
            this.$refs.modal.open();
            this.frameworkEditFlag = true;
            this.viewButtonVisible = {
                visibility: "hidden"
            };
        },
        closeArtifactUpload() {
            this.$refs.modal.close();
            this.fetchArtifactList(this.assessmentId);
            this.$refs.file.value = '';
            this.viewButtonVisible = {
                visibility: "hidden"
            };
            this.errors = [];
        },
        editArtifact(artifactDetails) {
            // let artifactUser = this.userList.map((user) => {
            //     if (user.artifactId == artifactDetails.id) return user;
            // })
            if (artifactDetails.artifactStatus != 'draft') {
                this.frameworkEditFlag = false;
                let frameworkName = null;
                for (let framework of this.frameworkList) {
                    if (framework._id == artifactDetails.frameworkId) {
                        frameworkName = framework.name;
                    }
                }
                this.editFrameworkName = frameworkName;
                /*                 let abc = this.editFrameworkName.join(', ')
                                abc.replace
                
                                console.log("AFTER COMMA REMOVE", this.editFrameworkName) */
            }
            else {
                this.frameworkEditFlag = true;
            }
            this.ArtifactModel = artifactDetails;
            this.artifactTypeErrorCheck();
            this.file = undefined;
            this.viewButtonVisible = {
                visibility: "visible"
            };
            this.$refs.modal.open();
        },
        deleteButton(artifact) {
            if (artifact.artifactStatus != 'draft') {
                showToast('Artifact already started. Cannot delete the artifact.', 'error', 'validationerror');
            }
            else {
                popupService.togglePopup();
                popupService.togglePopupMessage("Delete Action", "This action will delete the selected artifact and all the associated users and scoring records with it. Are you sure you want to delete?",
                    (action) => {
                        if (action === 'yes') {
                            this.deleteSelectedArtifact(artifact.id);
                        }
                        else {
                            popupService.togglePopup();
                        }
                    });
            }
        },
        openFileDialog() {
            event.preventDefault();
            let fileInputElement = this.$refs.file;
            fileInputElement.click();
        },
        nextClick() {
            if (this.emptyArtifact) {
                this.$router.push({ name: "assignUserList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast('Artifact is not created', 'error', 'validationerror')
            }
        },
        getStatus(status) {
            this.status = statusService.getStatus(status)

        },
        viewFramework() {
            const routerData = this.$router.resolve({ name: "viewFramework", params: { frameworkId: this.ArtifactModel.frameworkId } });
            window.open(routerData.href, 'blank');

        },

        searchByArtifactName() {
            if (this.searchText) {
                this.displayedArtifactList = this.assessmentArtifactList.filter(artifactDetails =>
                    artifactDetails.artifactName.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedArtifactList.length === 0) {
                    this.showDisplayMessage = "No Artifacts available for searched text";
                } else {
                    this.showDisplayMessage = "";
                }
            } else {
                this.displayedArtifactList = this.assessmentArtifactList;
                this.showDisplayMessage = "";
            }
        },
        async fetchAssessment(assessmentId) {
            try {
                const response = await assessmentService.fetchAssessmentById(assessmentId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.AssessmentModel = response.data.data;
                    sharedService.setData('assessment.model', this.AssessmentModel);
                } else {
                    showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        },
        async fetchArtifactList(assessmentId) {
            try {
                const response = await artifactService.fetchArtifactListByAssessmentId(assessmentId)
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.assessmentArtifactList = response.data.data
                    if (this.assessmentArtifactList.length <= 0) {
                        this.showDisplayMessage = " Add Artifacts to be displayed";
                        this.tableBodyStyles = {
                            display: 'flex !important',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }
                        this.emptyArtifact = false;
                    } else {
                        this.showDisplayMessage = "";
                        this.tableBodyStyles = {
                            display: 'block !important',
                        }
                        this.emptyArtifact = true
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast('Failed to fetch ArtifactList For this Assessment', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }

        },

        /*  async fetchArtifactUserList(assessmentId) {
              try {
                  const response = await userService.fetchUserListByAssessmentId(assessmentId)
                  console.log("response", response);
                  if (response.data.header.responseMessage === "SUCCESS") {
                      this.userList = response.data.data;
                  } else {
                      showToast('Failed to User List for this Assessment', 'error', 'validationerror')
                  }
              }
              catch (error) {
                  showToast(error.response.data.message, 'error', 'validationerror');
              }
          },*/
        async fetchFrameworkList() {
            try {
                const response = await artifactService.fetchFrameworkList()
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.frameworkList = response.data.data.filter(framework => framework.status === 'submitted');
                    this.frameworkTypes = [...new Set(this.frameworkList.map(framework => framework.type))]
                } else {
                    showToast('Failed to fetch frameworkList ', 'error', 'validationerror')
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
        },
        async saveArtifact(formData) {
            try {
                const response = await artifactService.saveArtifact(formData);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.saveArtifactResponse = response.data.data;
                    sharedService.setData('artifact.model', this.saveArtifactResponse);
                    showToast("Artifact saved successfully", 'success', 'validationsuccess');
                    this.closeArtifactUpload();
                } else {
                    showToast("Failed to Create Artifact", 'error', 'validationerror');
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }

        },

        async updateArtifact(artifact) {

            try {
                const updatedArtifactResponse = await artifactService.updateArtifact(artifact);
                if (updatedArtifactResponse.data.header.responseMessage === "SUCCESS") {
                    this.saveArtifactResponse = updatedArtifactResponse.data.data;
                    sharedService.setData('artifact.model', this.saveArtifactResponse);
                    showToast("Artifact updated successfully", 'success', 'validationsuccess');
                    this.closeArtifactUpload();
                } else {
                    showToast("Failed to update Artifact", 'error', 'validationerror');
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }


        },
        async deleteSelectedArtifact(artifactId) {
            try {
                const response = await artifactService.deleteArtifact(artifactId);
                //if (response.data.header.responseMessage === "SUCCESS")
                if (response.data.data === "SUCCESS") {
                    showToast("Artifact Delete Successfully", 'success', 'validationsuccess');
                    this.fetchArtifactList(this.assessmentId);

                } else {
                    showToast("Failed to Delete Artifact", 'error', 'validationerror');
                }
            }
            catch (error) {
                showToast(error.response.data.message, 'error', 'validationerror');
            }
            popupService.togglePopup();
        },

        sortArtifact(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedArtifactList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedArtifactList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.assessmentArtifactList
            );
            // set pagination data from the service
            this.displayedArtifactList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.assessmentArtifactList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
    },
};