<template>
  <HeaderComponent />
  <FooterComponent />
  <SideNav />
  <ToastComponent :visible="toastState.visible" :message="toastState.message" :type="toastState.type"
    :imageType="toastState.imageType"></ToastComponent>

  <div class="container-fluid p-0">
    <div class="row login-bg m-0">

      <div class="rightContainer d-flex justify-content-end p-0 m-0" :style="popUpModuleStyles">
        <div class="signIn"></div>
        <div class="mx-auto d-flex flex-column justify-content-start align-items-center searchAssessmentBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3" :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="d-flex justify-content-end align-items-center" style="width:90%">
            <button v-if="searchFilter.scoringScreen" type="search" class="btn btn-primary saveLaterButton"
              id="viewScoreButton">
              <div class="fontSize150" @click="backToScoringScreen()">View Score</div>
            </button>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2 headingTextBox p-0">
            <div class="d-flex justify-content-center align-items-center" style="margin-left:3%">
              <span class="viewIcon"></span>
              <span>Dashboard</span>
            </div>

            <div class="d-flex">
              <div class="d-flex" v-if="this.loggedInRole == 'frameworkAdmin' && this.Score != undefined">
                <div class="d-flex justify-content-center align-items-center" v-if=" this.showLockUnlockIcon && this.selectedAssessor.artifactStatus == 'approved'"
                  v-on:click="lockArtifactPopup()">
                  <span class="lock_blue me-1"></span>
                  <span class="lockOption mt-2">Lock & Finalize </span>
                </div>

                <div class="d-flex ustify-content-center align-items-center" v-if=" this.showLockUnlockIcon && this.selectedAssessor.artifactStatus == 'finalized'"
                  v-on:click="unlockArtifactPopup()">
                  <span type="search" class="unlock_blue me-1"></span>
                  <span class="lockOption mt-2">UnLock </span>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center z-1 position-relative" v-if="displayChart">
                <span type="search" class="exportIcon" v-on:click="downloadPdfFile()"></span>
                <span class="exportOption mt-2" v-on:click="downloadPdfFile()">Export</span>
              </div>
            </div>


          </div>

          <div class="whiteBoxOne ms-4 mb-5" v-if="searchFilter.display">
            <div class="subHeadingText mt-1 mb-3 row g-3 p-0">Search Filter</div>
            <form class="row g-3 p-0">
              <div class="d-flex flex-column" style="width:100%">
                <div class="d-flex justify-content-center align-items-center">
                  <div v-if="this.loggedInRole == 'frameworkAdmin'" class="col-4 d-flex flex-column">
                    <label for="clientName" class="form-label createAssessmentLabel"
                      v-if="searchFilter.showDetails">Client Name</label>
                    <span class="mandatoryFieldInput" v-if="!searchFilter.showDetails"> <label for="clientName"
                        class="form-label createAssessmentLabel">Client Name</label> * </span>
                    <div class="dropdownSelect" v-if="!searchFilter.showDetails">
                      <select v-if="ClientModel" v-model="ClientModel" id="clientName" class="dynamicDropdown" :value="value" ref='input'
                        @change="validateClient">
                        <option disabled selected :value="{}" class="dropdownOption">Client Name</option>
                        <option v-for="client in clientList" :key="client.id" :value="client" selected>
                          {{ client.clientName }}
                        </option>
                      </select>
                      <div v-if="errors.clientName" class="validationMessage">{{ errors.clientName }}</div>
                    </div>
                    <div class="col-4 d-flex flex-column" v-if="searchFilter.showDetails">
                      <div class="dashboardPlaceHolder">
                        <input v-if="ClientModel" v-model="ClientModel.clientName" name="clientName" type="text" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                  </div>
                  <div v-if="this.loggedInRole == 'frameworkAdmin'" class="col-4 d-flex flex-column">
                    <label for="projectName" class="form-label createAssessmentLabel"
                      v-if="searchFilter.showDetails">Project Name</label>
                    <span class="mandatoryFieldInput" v-if="!searchFilter.showDetails"> <label for="projectName"
                        class="form-label createAssessmentLabel">Project Name</label> * </span>
                    <div class="dropdownSelect" v-if="!searchFilter.showDetails">
                      <select v-if="ProjectModel" v-model="ProjectModel" id="projectName" class="dynamicDropdown" :value="value" ref="input"
                        @change="selectedProject" :disabled="!ClientModel.clientName">
                        <option disabled selected :value="{}" class="dropdownOption">Project Name</option>
                        <option v-for="project in projectList" :key="project.id" :value="project" selected>
                          {{ project.projectName }}
                        </option>
                      </select>
                      <div v-if="errors.projectName" class="validationMessage">{{ errors.projectName }}</div>
                    </div>
                    <div class="col-4 d-flex flex-column" v-if="searchFilter.showDetails">
                      <div class="dashboardPlaceHolder">
                        <input v-if="ProjectModel" v-model="ProjectModel.projectName" name="clientName" type="text" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                  </div>
                  <div class="col-4 d-flex flex-column">
                    <label for="status" class="form-label createAssessmentLabel"
                      v-if="searchFilter.showDetails">Assessment Name</label>
                    <span class="mandatoryFieldInput" v-if="!searchFilter.showDetails"> <label for="status"
                        class="form-label createAssessmentLabel">Assessment Name</label> * </span>
                    <div class="dropdownSelect" v-if="!searchFilter.showDetails">
                      <select v-if="AssessmentModel" v-model="AssessmentModel" id="assessmentName" class="dynamicDropdown" 
                      :value="value" ref="input" @change="assessmentValidation" :title="AssessmentModel?.assessmentName?.length > 30 ? AssessmentModel?.assessmentName : ''">
                        <option disabled selected :value="{}" class="dropdownOption">Assessment Name</option>
                        <option v-for="assessment in assessmentList" :key="assessment.id" :value="assessment"
                          :title="assessment.assessmentName.length > 30 ? assessment.assessmentName : ''"
                          selected>
                          {{ truncateText(assessment.assessmentName, 30) }}
                        </option>
                      </select>
                      <div v-if="errors.assessmentName" class="validationMessage">{{ errors.assessmentName }}</div>
                    </div>
                    <div class="col-4 d-flex flex-column" v-if="searchFilter.showDetails">
                      <div class="dashboardPlaceHolder">
                        <input v-if="AssessmentModel" v-model="AssessmentModel.assessmentName" name="assessmentName" type="text" readonly
                          class="fontSize150" />
                      </div>
                    </div>
                  </div>
                  <!--   <label for="artifactType" class="form-label createAssessmentLabel">Artifact Type</label>
                    <div class="dropdownSelect">
                        <select v-model="artifactType" id="artifactType" class="dynamicDropdown" :value="value" ref="input" @change="loadArtifactNameByType($event)">
                          <option disabled selected :value="{}" class="dropdownOption">Artifact Type</option>
                          <option v-for="artifact in artifactTypeList" :key="artifact.artifactType" :value="artifact" selected >
                            {{ artifact}}
                          </option>
                        </select>
                        <div v-if="errors.artifactType" class="validationMessage">{{ errors.artifactType }}</div>
                    </div>
                </div> -->
                  <div v-if="this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer'"
                    class="col-4 d-flex flex-column">
                    <span class="mandatoryFieldInput"> <label for="artifactName"
                        class="form-label createAssessmentLabel">Artifact Name</label> * </span>
                    <div class="dropdownSelect">
                      <select v-if="ArtifactModel" v-model="ArtifactModel" id="artifacttName" class="dynamicDropdown" :value="value"
                        ref="input" @change="artifactNameValidation" :disabled="!AssessmentModel.assessmentName">
                        <option disabled selected :value="{}" class="dropdownOption">Artifact Name</option>
                        <option v-for="artifact in artifactList" :key="artifact.id" :value="artifact" selected>
                          {{ artifact.artifactName }}
                        </option>
                      </select>
                      <div v-if="errors.artifactName" class="validationMessage">{{ errors.artifactName }}</div>
                    </div>
                  </div>
                  <div v-if="this.loggedInRole == 'reviewer'" class="col-4 d-flex flex-column">
                    <span class="mandatoryFieldInput"><label for="artifactAssessor"
                        class="form-label createAssessmentLabel"> Assessor</label>* </span>
                    <div class="dropdownSelect">
                      <select v-model="selectedAssessor" id="artifactAssessor" class="dynamicDropdown"
                        @change="setSelectedAssessor(selectedAssessor)" :disabled="!ArtifactModel.artifactName">
                        <option disabled selected :value="{}" class="dropdownOption">Select Assessor</option>
                        <option v-for="assessor in artifactAssessorList" :key="assessor.id" :value="assessor" selected>
                          {{ assessor.emailId }}
                        </option>
                      </select>
                      <div v-if="errors.artifactAssessor" class="validationMessage">{{ errors.artifactAssessor }}</div>
                    </div>
                  </div>
                  <div v-if="this.loggedInRole == 'assessor'" class="col-4 d-flex flex-column">
                    <label for="status" class="form-label createAssessmentLabel">Assessor Artifact Status</label>
                    <div class="col-4 d-flex flex-column">
                      <div class="dashboardPlaceHolder">
                        <input :class="getStatus(selectedAssessor.artifactStatus)" v-model="status"
                          name="artifactStatus" id="artifactStatus" type="text" placeholder="Assessor Artifact Status"
                          readonly class="fontSize150" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-end mt-5 marginTopResponsive">
                  <!-- <div v-if="this.loggedInRole == 'frameworkAdmin'" class="col-4 d-flex flex-column">
                  <label for="artifactType1" class="form-label createAssessmentLabel">Artifact Type</label>
                    <div class="dropdownSelect">
                        <select v-model="artifactType" id="artifactType1" class="dynamicDropdown" :value="value" ref="input" @change="loadArtifactNameByType($event)" :disabled="!AssessmentModel.assessmentName">
                          <option disabled selected :value="undefined" class="dropdownOption">Artifact Type</option>
                          <option v-for="artifact in artifactTypeList" :key="artifact.artifactType" :value="artifact" selected >
                            {{ artifact }}
                          </option>
                        </select>
                        <div v-if="errors.artifactType" class="validationMessage">{{ errors.artifactType }}</div>
                    </div>
                </div> -->
                  <div v-if="this.loggedInRole == 'frameworkAdmin'" class="col-4 d-flex flex-column">
                    <span class="mandatoryFieldInput"><label for="artifactName1"
                        class="form-label createAssessmentLabel">Artifact Name</label> * </span>
                    <div class="dropdownSelect">
                      <select v-if="ArtifactModel" v-model="ArtifactModel" id="artifacttName1" class="dynamicDropdown" :value="value"
                        ref="input" @change="artifactNameValidation" :disabled="!AssessmentModel.assessmentName">
                        <option disabled selected :value="{}" class="dropdownOption">Artifact Name</option>
                        <option v-for="artifact in artifactList" :key="artifact.id" :value="artifact" selected>
                          {{ artifact.artifactName }}
                        </option>
                      </select>
                      <div v-if="errors.artifactName" class="validationMessage">{{ errors.artifactName }}</div>
                    </div>
                  </div>
                  <div v-if="this.loggedInRole == 'frameworkAdmin'" class="col-4 d-flex flex-column">
                    <span class="mandatoryFieldInput"><label for="artifactAssessor"
                        class="form-label createAssessmentLabel"> Assessor</label> * </span>
                    <div class="dropdownSelect">
                      <select v-model="selectedAssessor" id="artifactAssessor" class="dynamicDropdown"
                        @change="setSelectedAssessor(selectedAssessor)" :disabled="!ArtifactModel.artifactName">
                        <option disabled selected :value="{}" class="dropdownOption">Select Assessor</option>
                        <option v-for="assessor in artifactAssessorList" :key="assessor.id" :value="assessor" selected>
                          {{ assessor.emailId }}
                        </option>
                      </select>
                      <div v-if="errors.artifactAssessor" class="validationMessage">{{ errors.artifactAssessor }}</div>
                    </div>
                  </div>
                  <div class="col-4 d-flex flex-column"
                    v-if="this.loggedInRole == 'frameworkAdmin' || this.loggedInRole == 'reviewer'">
                    <label for="status" class="form-label createAssessmentLabel">Assessor Artifact Status</label>

                    <div class="col-4 d-flex flex-column">
                      <div class="dashboardPlaceHolder">
                        <input :class="getStatus(selectedAssessor.artifactStatus)" v-model="status"
                          name="artifactStatus" id="artifactStatus" type="text" placeholder="Assessor Artifact Status"
                          readonly class="fontSize150" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center" style="width:99.5%;">
                <div class="d-flex justify-content-between align-items-center" style="margin:-1em">
                  <div class="d-flex">
                    <button type="search" class="btn btn-primary saveLaterButton"
                      v-on:click="buttonClick(ArtifactModel.id)">
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="row d-flex flex-column justify-content-center align-items-center ms-3" id="showChart"
            v-if="displayChart" style="width: 89%;">
            <div class="d-flex justify-content-center align-items-start ms-3 mt-0">
              <div class="whiteBox" style="width: 30%; height: 31em;" v-if="whiteBoxVisible"
                id="whiteBoxforSemiDonutChart">
                <div class="mt-3 ms-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="subHeadingText">RQD Score</div>
                    <!-- <div class="popOut justify-content-end"></div> -->
                  </div>
                  <hr style="height: 2px; border-width: 0; color: black; background-color: black;" />
                  <!-- <semiCircleChart class="myChart1" v-if="showSemiCircleChart"></semiCircleChart> -->
                  <div class="semiDonutChart" v-if="showSemiCircleChart">
                    <div class="legendContainer d-flex justify-content-end align-items-center" id="showLegend">
                      <div class="legendItem d-flex justify-content-center align-items-center">
                        <span class="legendColor" style="background-color: #e86959;"></span>&nbsp;
                        <span>Develop</span>
                      </div>
                      <div class="legendItem d-flex justify-content-center align-items-center">
                        <span class="legendColor" style="background-color: #eea616;"></span>&nbsp;
                        <span>Sufficient</span>
                      </div>
                      <div class="legendItem d-flex justify-content-center align-items-center">
                        <span class="legendColor" style="background-color: #26ce96;"></span>&nbsp;
                        <span>Optimal</span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div style="width: 530px; height: 350px;" id="divforSemiDonutChart">
                        <canvas id="semiDonutChart" width="100" height="100"></canvas>
                      </div>
                    </div>
                    <div class="needleValue d-flex justify-content-center align-items-center mt-2">
                      Overall RQD Score: {{ gaugeValue }}%
                    </div>
                  </div>
                </div>
              </div>
              <div class="whiteBox2 ms-5" style="width: 64%; height: 31em;" v-if="whiteBoxVisible"
                id="whiteBoxforDonutChart">
                <div class="mt-3 ms-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column justify-content-between col-5">
                      <div class="subHeadingText">Category Score</div>
                      <hr style="height: 2px; border-width: 0; color: #000000; background-color: black;width: 100%;" />
                      <div class="d-flex justify-content-start align-items-center" v-if="showDonutChart">
                        <div style="width: 530px; height: 400px;" class="chart-wrapper" :style="chartWrapper">
                          <canvas ref="chart" width="420" height="400" id="donutChart" style="z-index: 2;"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="lineSeparator" v-if="showForSubcatChart">

                      </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between col-5 subcategoryColumn"
                      style="margin-top: -0.85em;">
                      <div class="subHeadingText" v-if="showForSubcatChart" v-html="subCategoryScoreHeading"></div>
                      <hr style="height: 2px; border-width: 0; color: #000000; background-color: black;width: 100%;"
                        v-if="showForSubcatChart" />
                      <div class="d-flex justify-content-start align-items-center">
                        <div style="width: 530px; height: 400px;" class="subcatChart-wrapper"
                          :style="subCatChartStyles">
                          <canvas ref="subCatChart" width="420" height="400" id="subCatDonutChart"
                            :style="subCategoryDonutChart"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--                   <div class="d-flex justify-content-between align-items-center">
                    <div class="subHeadingText">Category Score</div>
                    <div class="subHeadingText">SubCategory Score</div>
                  </div>
                  <hr style="height: 2px; border-width: 0; color: #000000; background-color: black;width: 41.5%;" />
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex justify-content-start align-items-center col-5" v-if="showDonutChart">
                        <div style="width: 530px; height: 400px;" class="chart-wrapper">
                          <canvas ref="chart" width="420" height="400" id="donutChart" style="z-index: 2;"></canvas>
                        </div>
                      </div>
                      <div class="lineSeparator col-2">

                      </div>
                      <div class="d-flex justify-content-start align-items-center col-5">
                        <div style="width: 530px; height: 400px;" class="subcatChart-wrapper">
                          <canvas ref="subCatChart" width="420" height="400" id="subCatDonutChart"></canvas>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-start mt-5 ms-3 marginBottomForWhitebox">
              <div class="whiteBox" style="width: 98%; height: 46em;" v-if="whiteBoxVisible" id="whiteBoxSpiderAndBar">
                <div class="mt-3 ms-0">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="subHeadingText">Subcategory Score</div>
                    <!-- <div class="popOut justify-content-end"></div> -->
                  </div>
                  <hr style="height: 2px; border-width: 0; color: black; background-color: black;" />
                  <div class="d-flex justify-content-end align-items-center">
                    <!-- <div class="popOut d-flex justify-content-end align-items-end"></div> -->
                  </div>
                  <div class="row d-flex justify-content-between align-items-center">
                    <div class="col-6" style="width: 50%;">
                      <!-- <spiderChart v-if="showDonutChart"></spiderChart> -->
                      <div class="d-flex justify-content-center align-items-start" v-if="showSpiderChart">
                        <div>
                          <canvas ref="spiderChart" width="650" height="650" id="spiderChartResponsive"></canvas>
                        </div>
                      </div>
                    </div>
                    <div class="col-6" style="width: 50%;">
                      <!-- <barChart v-if="showBarChart"></barChart>  -->
                      <div class="d-flex justify-content-start align-items-start" v-if="showBarChart">
                        <div style="width: 650px; height: 350px;" id="barchartResponsive">
                          <canvas ref="barChart" width="300" height="200" id="barChart"></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUpModule v-if="showPopUpModule" :headerMessage=headerMessage :message="dynamicMessage"
        @confirm="popupConfirm(popupAction)" @cancel="closePopup"></PopUpModule>

    </div>
  </div>
</template>
<script src="./frameworkDashboard.controller.js"></script>
<style scoped src="./frameworkDashboard.style.css"></style>