import sharedService from '@/app/services/shared.service';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import { showToast, toast } from '@/app/shared/toast/toast.controller';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import userService from '../user.service';
import CreateUser from './CreateUser.vue';



export default {
    name: "createUser",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        CreateUser,
        SideNav,
        ToastComponent


    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    data() {
        return {
            successToastMsg: "",
            errorToastMsg:"",
            UserModel: {
                isActive: "Y",
            },
            userId: "",
            editUser: false,
            errors: {
                firstName: "",
                lastName: "",
                primaryRole: "",
                emailId: "",
                phoneNumber: "",

            },
            CreateUser,
            createUserResponse: {},
            showBreadcrumbs: false,
        };
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    created() {
        this.inputReadOnly = {
            color: "#767676 !important",
        };
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))')
        const macScreen = window.matchMedia('((min-width:1441px) and (max-width:1600px))')
        const mediaQueryMidScreen = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        if (mediaQueryMidScreen.matches) {
            this.breadcrumbStyles = {
                width: "93%",
            };
        }
        else if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "93%",
            }
        }
        else if(macScreen.matches){
            this.breadcrumbStyles = {
                width: '92.5%',
            }
        }
        else{
            this.breadcrumbStyles = {
                width: "91.5%",
            };
        }

        this.userId = this.$route.params.userId;
        this.$store.commit("addToHistory", this.$route);
        if (this.userId) {
            this.routePath = "/editUser";
            // this.UserModel = sharedService.getData("user.model");
            // if (!this.UserModel) {
            this.fetchUser(this.userId);
            // }
            this.editUser = true;
            this.successToastMsg="User Updated Successfully";
            this.errorToastMsg="Failed to update User";
        } else {
            this.routePath = "/createUser";
            this.successToastMsg="User Created Successfully"
            this.errorToastMsg="Failed to create User";
        }
    },

    methods: {

        cancelClick() {
            this.$router.push("/user/list");
            this.createUserClick();
        },

        firstNameValidation() {
            if (this.UserModel.firstName == undefined || this.UserModel.firstName == "" || this.UserModel.firstName == null) {
                this.errors.firstName = "Please enter firstName";
            } else {
                this.errors.firstName = "";
                this.UserModel.firstName = this.UserModel.firstName.charAt(0).toUpperCase() + this.UserModel.firstName.slice(1);

            }
        },
        lastNameValidation() {

            if (this.UserModel.lastName == undefined || this.UserModel.lastName == "" || this.UserModel.lastName == null) {
                this.errors.lastName = "Please enter lastName";
            } else {
                this.errors.lastName = "";
                this.UserModel.lastName = this.UserModel.lastName.charAt(0).toUpperCase() + this.UserModel.lastName.slice(1);

            }

        },
        userRoleValidation() {

            if (this.UserModel.primaryRole == undefined) {
                this.errors.primaryRole = "Please select Primary role";
            } else {
                this.errors.primaryRole = "";

            }

        },

        emailIdValidation() {

            if (this.UserModel.emailId == undefined || this.UserModel.emailId == "" || this.UserModel.emailId == null) {
                this.errors.emailId = "Please Enter an email Id";
            } else {
                this.errors.emailId = "";
                this.UserModel.emailId = this.UserModel.emailId.toLowerCase();
            }

        },
        validatingEmail(emailId) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.com$/;
            if (!regex.test(emailId)) {
                this.errors.emailId = "Please enter the Email in correct format";
                event.preventDefault();
            } else {
                this.validateEmail = true;
            }
            return this.validateEmail
        },
        clearEmail() {
            this.errors.emailId = "";
        },

        phoneNumberValidation() {
            if (this.UserModel.phoneNumber == undefined || this.UserModel.phoneNumber == "" || this.UserModel.phoneNumber == null) {
                this.errors.phoneNumber = "Please Enter  phoneNumber";
            }
            else {
                this.errors.phoneNumber = "";
            }

        },

        filterPhoneNumber(event) {
            const value = event.target.value.replace(/\D/g, "");
            this.UserModel.phoneNumber = value;

        },

        createUserClick() {
            if (this.UserModel.firstName == undefined || this.UserModel.firstName == "" || this.UserModel.firstName== null)  {
                this.firstNameValidation();
            } else if (this.UserModel.lastName == undefined || this.UserModel.lastName == "" || this.UserModel.lastName == null) {
                this.lastNameValidation();
            } else if (this.UserModel.primaryRole == undefined) {
                this.userRoleValidation();
            } else if (this.UserModel.emailId == undefined || this.UserModel.emailId == "" || this.UserModel.emailId == null) {
                this.emailIdValidation();
            }
            //  else if (this.UserModel.phoneNumber == undefined || this.UserModel.phoneNumber.length < 6) {
            //     this.phoneNumberValidation();
            // } 
            else {
                this.createUser();
            }
            event.preventDefault();
        },

        async createUser() {
            try {
                const response = await userService.createUserAPI(this.UserModel);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.createUserResponse = response.data.data;
                    this.UserModel.id = this.createUserResponse.id;
                    // sharedService.setData(
                    //     "user.model",
                    //     this.createUserResponse
                    // );
                    showToast(this.successToastMsg, "success", "validationsuccess");
                    this.$router.push("/user/list");
                } else {
                    showToast(this.errorToastMsg, "error", "validationerror");
                }
            } catch (error) {
                showToast(error.response.data.message, "error", "validationerror");
                console.log('controller error log', error);
            }
        },

        async fetchUser(userId) {
            try {
                const response = await userService.fetchUserById(userId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.UserModel = response.data.data;
                    sharedService.setData("user.model", this.UserModel)
                } else {
                    showToast(
                        "Failed to fetch User Details",
                        "error",
                        "validationerror"
                    );

                }
            } catch (error) {
                showToast(error.response.data.message, "error", "validationerror");
            }
        },
    }
}