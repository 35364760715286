import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { SweetModal, SweetModalTab } from "sweet-modal-vue-3";
import Paginate from "vuejs-paginate-next";
import clientService from '../client/client.service';
import frameworkService from '../FrameworkDetails/framework.service';
import { paginationService } from '../services/pagination.service.js';
import { default as SharedService, default as sharedService } from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js';
import statusService from '../services/status.service';
import popupService from '../shared/custompopup/popupModule.service';
import InfoPopup from "../shared/infopopup/InfoPopup.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';

/* import {confirmDelete} from "../shared/custompopup/popupModule.controller.js" */
export default {
    name: "searchAssessment",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        SweetModal,
        SweetModalTab,
        paginate: Paginate,
        ToastComponent,
        InfoPopup,
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    created() {
        this.fetchFrameworkList();
        this.onLoadClientName();
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        const midScreenMedia = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        const largeScreenMedia = window.matchMedia('((min-width:1871px) and (max-width:1920px))')
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "82%",
            }
        }
        else if (midScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "80.5%",
            }
        }
        else if (largeScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "83%",
            }
        }
        else {
            this.breadcrumbStyles = {
                width: "81%",
            };
        }
        // this.showEmptyTableContent = true
        this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            clientList: [],
            frameworkList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            frameworkTypes: ["Usecase", "BRD", "FRD"],
            FrameworkModel: {
                status: "draft",
                clientId: [],
            },
            errors: {
                name: '',
                frameworkType: '',
                clientId: '',
                frameworkDescription: '',
            },
            showCreateFrameworkButton: true,
            validateClientName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            showPopUpModule: false,
            showModuleStyles: {},
            showEmptyTableContent: "",
            showFrameworkListTableContent: true,
            showToolTipIndex: null,
            activeTooltipType: null,
            showInfoPopUpModule: false,
            sortOrder: {
                frameworkName: "common",
                frameworkType: "common",
                status: "common"

            },
            frameworkListResponse: [],
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedFrameworkList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
            tooltipText: "",
            showDropdown: false,
            createframeworkFlag: false
        };
    },


    computed: {
        filteredFrameworkList() {
            return this.displayedFrameworkList;
        }
    },

    mounted() {
        document.addEventListener("click", this.closeDropdown);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.closeDropdown);
        this.$store.commit("removeFromHistory", this.routePath);
    },

    methods: {

        showToolTip(index, type, framework = null) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;

            if (type === 'edit') {
                this.tooltipText = 'Edit'
            } else if (type === 'configure') {
                this.tooltipText = framework && framework.status === 'submitted' ? 'View' : 'Configure';
            } else if (type === 'clone') {
                this.tooltipText = 'Clone';
            } else if (type === 'delete') {
                this.tooltipText = 'Delete';
            }
        },
        hideToolTip() {
            this.showToolTipIndex = null;
            this.activeTooltipType = null;
            this.tooltipText = "";
        },
        isLastRow(index) {
            return index === this.filteredFrameworkList.length - 1 && this.filteredFrameworkList.length > 1;
        },
        truncateClientNames(clientName) {
            if (clientName.length > 25) {
                return clientName.slice(0, 24) + '...';
            }
            return clientName;
        },
        getStatus(status) {
            this.status = statusService.getStatus(status)
        },

        getSelectedClientNames() {
            return this.clientList.filter(client => this.FrameworkModel.clientId.includes(client.id)).map(client => client.clientName).join(",");
        },
        getTruncatedClientsNames() {
            const fullNames = this.getSelectedClientNames();
            if (fullNames.length > 30) {
                return fullNames.slice(0, 30) + "...";
            }
            return fullNames || "Select Clients";
        },
        createNewFramework() {
            this.FrameworkModel = {
                status: "draft",
                clientId: [],
            }
            this.errors = {
                name: '',
                frameworkType: '',
                clientId: '',
                frameworkDescription: '',
            },
                this.createframeworkFlag = true;
            this.showDropdown = false;
            this.$refs.modal.open();
        },
        editFramework(framework) {
            this.FrameworkModel.name = framework.name;
            this.FrameworkModel._id = framework._id;
            this.FrameworkModel.type = framework.type;
            this.FrameworkModel.status = framework.status;
            this.FrameworkModel.description = framework.description;
            this.FrameworkModel.clientId = framework.clients.map(client => client.id);
            this.FrameworkModel.clients = framework.clients;
            sharedService.setData("framework.model", this.FrameworkModel);
            this.showDropdown = false;
            this.createframeworkFlag = false;
            this.$refs.modal.open();
        },
        configureFramework(framework) {
            sharedService.setData("framework.model", framework);
            if (framework.status === 'submitted') {
                this.$router.push({ name: "viewFramework", params: { frameworkId: framework._id } });
            }
            else {
                this.$router.push({ name: "editFramework", params: { frameworkId: framework._id } });
            }

        },
        popupConfirm(action) {
            if (action == 'delete') {
                this.deleteSelectedFramework();
            }
            if (action == 'clone') {
                this.cloneSelectedFramework();
            }

        },
        cloneFramework(framework) {
            popupService.togglePopup();
            popupService.togglePopupMessage("Delete Action", "This action will clone the selected Framework . Are you sure want to continue?",
                (action) => {
                    if (action === 'yes') {
                        this.cloneSelectedFramework(framework._id);
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            );
        },
        deleteFramework(framework) {
            if (framework.isActive == 'N') {
                popupService.togglePopup();
                popupService.togglePopupMessage("Delete Action", "This action will delete the selected Framework and non reversible. Are you sure you want to delete?"
                    ,
                    (action) => {
                        if (action === 'yes') {
                            this.deleteSelectedFramework(framework._id);
                        }
                        else {
                            popupService.togglePopup();
                        }
                    }
                );
            } else {
                this.showInfoPopUpModule = true;
            }
        },
        closePopUp() {
            this.showPopUpModule = false;
            this.showInfoPopUpModule = false;
        },

        searchByFrameworkName() {
            if (this.searchText) {
                this.displayedFrameworkList = this.frameworkList.filter(framework =>
                    framework.name.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedFrameworkList.length === 0) {
                    this.showEmptyTableContent = "No frameworks available for searched text";
                } else {
                    this.showEmptyTableContent = "";
                }
            } else {
                this.displayedFrameworkList = this.frameworkList;
                this.showEmptyTableContent = "";
            }
        },

        sortFramework(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedFrameworkList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedFrameworkList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.frameworkList
            );

            // set pagination data from the service
            this.displayedFrameworkList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.frameworkList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.showDropdown = false;
            }
        },
        frameworkNameValidation() {
            if (this.FrameworkModel.name == undefined || this.FrameworkModel.name == null || this.FrameworkModel.name == "") {
                this.errors.name = "Please enter framework name";
            } else {
                this.errors.name = "";
                this.FrameworkModel.name = this.FrameworkModel.name.charAt(0).toUpperCase() + this.FrameworkModel.name.slice(1);
            }
        },
        frameworkTypeValidation() {
            if (this.FrameworkModel.type == undefined) {
                this.errors.name = "";
                this.errors.frameworkType = "Please select framework type";
            } else {
                this.errors.frameworkType = "";
            }
        },

        frameworkClientValidation() {
            if (this.FrameworkModel.clientId == undefined || this.FrameworkModel.clientId.length == 0) {
                this.errors.frameworkType = "";
                this.errors.clientId = "Please select Clients";
            } else {
                this.errors.clientId = "";
            }
        },

        checkTextareaLength() {
            let descriptionCharacterLength = this.FrameworkModel.frameworkDescription
            if (descriptionCharacterLength === undefined || descriptionCharacterLength === null || descriptionCharacterLength === "") {
                descriptionCharacterLength = 0
            } else {
                descriptionCharacterLength = descriptionCharacterLength.length
            }
            this.$nextTick(() => {
                if (descriptionCharacterLength >= 250) {
                    this.errors.frameworkDescription = 'Please Enter valid Description less than 250 characters';
                } else {
                    this.errors.frameworkDescription = ''
                }
            })
        },
        createFramework() {

            this.frameworkValidation();
            if (this.validToSave) {
                this.createFrameworkAPI();
            }

        },

        frameworkValidation() {
            if (this.FrameworkModel.name == undefined) {
                this.frameworkNameValidation();
            } else if (this.FrameworkModel.type == undefined) {
                this.frameworkTypeValidation();
            }
            else if (this.FrameworkModel.clientId == undefined || this.FrameworkModel.clientId.length == 0) {
                this.frameworkClientValidation();
            }
            else {
                if (this.FrameworkModel.status == "Draft") {
                    this.FrameworkModel.status = "draft";
                }
                this.validToSave = true;
            }

            event.preventDefault();
        },
        async onLoadClientName() {
            try {
                const response = await clientService.fetchClientList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientList = response.data.data;
                } else {
                    showToast("Failed to fetch Clients", "error", 'validationerror');
                }
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Clients", "error", 'validationerror');
            }
        },

        async createFrameworkAPI() {
            try {
                const response = await frameworkService.createFramework(this.FrameworkModel);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.FrameworkModel.name = response.data.data.name;
                    this.FrameworkModel._id = response.data.data._id;
                    this.FrameworkModel.type = response.data.data.type;
                    this.FrameworkModel.status = response.data.status;
                    this.FrameworkModel.description = response.data.data.description;
                    this.FrameworkModel.clientId = response.data.data.clientId;
                    sharedService.setData("framework.model", this.FrameworkModel);
                    this.frameworkId = this.FrameworkModel._id;
                    if (this.createframeworkFlag) {
                        showToast("Framework created successfully", "success", 'validationsuccess');

                    }
                    else {
                        showToast("Framework updated successfully", "success", 'validationsuccess');
                    }
                    this.$refs.modal.close();
                    this.fetchFrameworkList();
                }
            }
            catch (error) {
                // Handle errors
                showToast(error.response.data.message, "error", "validationerror");
                //  return false;
            }

        },

        async fetchFrameworkList() {
            try {
                const response = await frameworkService.fetchFrameworkList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.frameworkListResponse = response.data.data;
                    this.frameworkList = [...this.frameworkListResponse];
                    if (this.frameworkList.length <= 0) {
                        this.showFrameworkListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = "No Framework to view, continue to create Framework";
                    } else {
                        this.showFrameworkListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = "";
                    }
                    this.updateDisplayedRecords();
                } else {
                    showToast("Failed to fetch Frameworks", "error", 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },
        deleteSelectedFramework(frameworkId) {
            frameworkService.deleteFramework(frameworkId).then(response => {
                //if (response.data.header.responseMessage === "SUCCESS")
                if (response.data.data === "SUCCESS") {
                    showToast("Framework Deleted Successfully", 'success', 'validationsuccess');
                    this.fetchFrameworkList();
                } else {
                    showToast("Failed to Delete Framework", 'error', 'validationerror');
                }
                popupService.togglePopup();
            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                });

        },
        cloneSelectedFramework(frameworkId) {
            frameworkService.copyFramework(frameworkId).then(response => {
                if (response.status == 200) {
                    showToast("Framework cloned Successfully", 'success', 'validationsuccess');
                    this.fetchFrameworkList();
                } else {
                    showToast("Error in clone", 'error', 'validationerror');
                }
                popupService.togglePopup();
            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast("Error in clone", 'error', 'validationerror');
                });

        },

    },
};