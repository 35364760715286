import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';

export default {

    async projectListByClientId(clientId) {
        return axios.get(`${apiEndpoint.projectListByClientId}` + clientId)
        .catch(function (error) {
            throw error;
        });

    },
    async createProjectAPI(client) {
        return axios.post(`${apiEndpoint.createProject}`, client)
        .catch(function (error) {
            throw error;
        });

    },
    async fetchProjectById(projectId) {
        return axios.get(`${apiEndpoint.fetchProject}` + projectId)
            .catch(function (error) {
                throw error;
            });

    },
    async deleteProject(projectId) {
        return axios.delete(`${apiEndpoint.deleteProject}` + projectId)
        .catch(function (error) {
            throw error;
        });

    }

}