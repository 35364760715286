import { createRouter, createWebHistory } from "vue-router";
// import AboutView from '../app/about/AboutView.vue'
import AssignUserList from "@/app/UserDetails/assignUser/AssignUserList.vue";
import ArtifactList from "../app/ArtifactDetails/ArtifactList.vue";
import CreateAssessment from "../app/AssessementDetails/CreateAssessment.vue";
import SearchAssessment from "../app/AssessementDetails/SearchAssessment.vue";
import FrameworkDashboard from "../app/Dashboard/FrameworkDashboard.vue";
import LandingPage from "../app/landingpage/LandingPage.vue";
import ResetPassword from "../app/login/reset-password/ResetPassword.vue";
/* import chartsWorld from "../app/charts/chartsWorld.vue"; */
import CreateClient from "@/app/client/create/CreateClient.vue";
import LoginComponent from "@/app/login/LoginComponent.vue";
import CreateProject from "@/app/project/create/CreateProject.vue";
import ProjectList from "@/app/project/list/ProjectList.vue";
import AssessmentScoring from "@/app/score/AssessmentScoring.vue";
import CreateUser from "@/app/UserDetails/create/CreateUser.vue";
import UserList from "@/app/UserDetails/list/UserList.vue";
import ClientList from "../app/client/list/ClientList.vue";
import CreateFramework from "../app/FrameworkDetails/CreateFramework.vue";
import FrameworkList from "../app/FrameworkDetails/FrameworkList.vue";
import ViewFramework from "../app/FrameworkDetails/ViewFramework.vue";

const routes = [
    // {
    //     path: '/',
    //     name: 'about',
    //     component: AboutView
    // },
    // {
    //     path: "/",
    //     name: "login",
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () =>
    //         import( /* webpackChunkName: "about" */ "../app/login/LoginComponent.vue"),
    // },
    {
        path: "/",
        name: "login",
        component: LoginComponent,
    },
    {
        path: "/landingPage",
        name: "LandingPage",
        component: LandingPage,
        meta: { breadcrumb: 'Home' }
    },
    {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: ResetPassword,
    },
    {
        path: "/changePassword/:token",
        name: "resetPassword",
        component: ResetPassword,
    },
    {
        path: "/createAssessment",
        name: "createAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'Create Assessment' }
    },
    {
        path: "/editAssessment/:assessmentId",
        name: "editAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'Edit Assessment' }
    },
    {
        path: "/viewAssessment/:assessmentId",
        name: "viewAssessment",
        component: CreateAssessment,
        meta: { breadcrumb: 'View Assessment' }
    },
    {
        path: "/createClient",
        name: "createClient",
        component: CreateClient,
        meta: { breadcrumb: 'Create Client' }
    },
    {
        path: "/clientList",
        name: "clientList",
        component: ClientList,
        props: true,
        meta: { breadcrumb: 'Client List' }
    },
    {
        path: "/editClient/:clientId",
        name: "editClient",
        component: CreateClient,
        meta: { breadcrumb: 'Edit Client' }
    },
    {
        path: "/createProject/:clientId",
        name: "createProject",
        component: CreateProject,
        meta: { breadcrumb: 'Create Project' }
    },
    {
        path: "/projectList/:clientId",
        name: "projectList",
        component: ProjectList,
        meta: { breadcrumb: 'Project List' }
    },
    {
        path: "/editProject/:projectId",
        name: "editProject",
        component: CreateProject,
        meta: { breadcrumb: 'Edit Project' }
    },
    {
        path: "/createUser",
        name: "createUser",
        component: CreateUser,
        meta: { breadcrumb: 'Create User' }
    },
    {
        path: "/editUser/:userId",
        name: "editUser",
        component: CreateUser,
        meta: { breadcrumb: 'Edit User' }
    },
    {
        path: "/user/list",
        name: "userList",
        component: UserList,
        props: true,
        meta: { breadcrumb: 'User List' }
    },
    {
        path: "/searchAssessment",
        name: "searchAssessment",
        component: SearchAssessment,
        props: true,
        meta: { breadcrumb: 'Search Assessments' }
    },
    {
        path: "/artifactList/:assessmentId",
        name: "artifactList",
        component: ArtifactList,
        meta: { breadcrumb: 'Artifact List' }
    },
    {
        path: "/assignUserList/:assessmentId",
        name: "assignUserList",
        component: AssignUserList,
        meta: { breadcrumb: 'Assign UserList' }
    },
    {
        path: "/frameworkDashboard",
        name: "frameworkDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'FrameworkDashboard' }
    },
    {
        path: "/frameworkDashboard/:assessmentId",
        name: "assessmentDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'AssessmentDashboard' }
    },
    {
        path: "/artifactFrameworkDashboard/:artifactId",
        name: "artifactFrameworkDashboard",
        component: FrameworkDashboard,
        meta: { breadcrumb: 'ArtifactDashboard' }
    },
    {
        path: "/assessmentScoring/:assessmentId/:assessmentName",
        name: "assessmentScoring",
        component: AssessmentScoring,
        meta: { breadcrumb: 'Artifact Scoring' }
    },
    {
        path: "/frameworkList",
        name: "frameworkList",
        component: FrameworkList,
        props: true,
        meta: { breadcrumb: 'Framework List' }
    },
    {
        path: "/createFramework",
        name: "createFramework",
        component: CreateFramework,
        props: true,
        meta: { breadcrumb: 'Create Framework' }
    },

    {
        path: "/configureFramework/:frameworkId",
        name: "editFramework",
        component: CreateFramework,
        props: true,
        meta: { breadcrumb: 'Configure Framework' }
    },

    {
        path: "/viewFramework/:frameworkId",
        name: "viewFramework",
        component: ViewFramework,
        meta: { breadcrumb: 'View Framework' }
    },
    // {
    //     path: "/assessmentScoring/:assessmentId",
    //     name: "assessmentScoring",
    //     component: AssessmentScoring,
    //     meta: { breadcrumb: 'Artifact Scoring' }
    // },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;