import SharedService from './app/services/shared.service.js';
import popupService from './app/shared/custompopup/popupModule.service.js';
import PopUpModule from './app/shared/custompopup/PopUpModule.vue';

export default {
    name: "App",
    components: {
        PopUpModule
    },
    setup() {
        return {
            popupService: popupService,
        }
    },
    created() {

    },
    data() {
        return {
            serverRequestOn: false,
        }
    },
    mounted() {
        setInterval(() => {
            this.serverRequestOn = SharedService.getSpinner();
        }, 100);
    },
    methods: {
        
    },
};
