import { reactive } from "vue";

const popupService = reactive({
    showPopup: false,
    headerMessage:"",
    message:"",
    buttonAction:null,


    togglePopup() {
        this.showPopup = !this.showPopup;
    },

    togglePopupMessage(headerMessage,message,buttonAction) {
        this.headerMessage=headerMessage;
        this.message=message;
        this.buttonAction=buttonAction
    },

    handleButtonClick(action) {
        if (this.buttonAction) {
            this.buttonAction(action);
        }
        // this.togglePopup();
    },
});

export default popupService;