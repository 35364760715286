import sharedService from '@/app/services/shared.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import clientListService from '../client.service.js';
// import sharedService from '@/app/services/sort.service.js';
import { paginationService } from '@/app/services/pagination.service.js';
import sortingService from '@/app/services/sort.service.js';
import statusService from '@/app/services/status.service.js';
import popupService from '@/app/shared/custompopup/popupModule.service.js';
import { showToast, toast } from '@/app/shared/toast/toast.controller.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import clientService from '../client.service.js';

export default {
    name: "clientList",
    components: {
        FooterComponent,
        HeaderComponent,
        BreadcrumbComponent,
        SideNav,
        ToastComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");

        return {
            toastState: toast,
            loggedInRole,
        };
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    created() {
        if (this.loggedInRole == 'frameworkAdmin') {
            this.fetchClientList();
        }

        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
        const midScreenMedia = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        const largeScreenMedia = window.matchMedia('((min-width:1871px) and (max-width:1920px))')
        if (mediaQuery.matches) {
            this.breadcrumbStyles = {
                width: "79.5%",
            }
        }
        else if (midScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "78%",
            }
        }
        else if (largeScreenMedia.matches) {
            this.breadcrumbStyles = {
                width: "81.5%",
            }
        }
        else {
            this.breadcrumbStyles = {
                width: "79%",
            };
        }
        this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);
        this.showBreadcrumbs = true;

    },
    data() {
        return {
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            ClientModel: {},
            showCreateFrameworkButton: true,
            validateClientName: false,
            navigationClick: "Home",
            breadcrumbStyles: {},
            showBreadcrumbs: false,
            deleteClientId: '',
            showEmptyTableContent: "",
            showclientListTableContent: true,
            showToolTipIndex: null,
            activeTooltipType: null,
            showInfoPopUpModule: false,
            sortOrder: {
                clientName: 'common',
                industry: 'common',
                status: 'common'

            },
            clientListResponse: [],
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedClientList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
        }
    },

    computed: {
        filteredClientList() {
            return this.displayedClientList;

        }
    },

    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        isLastRow(index) {
            return index === this.filteredClientList.length - 1 && this.filteredClientList.length > 1;
        },
        createNewClient() {
            this.$router.push("/createclientList");
        },
        addNewClientClick() {
            this.$router.push("/createClient");
        },
        editClient(client) {
            sharedService.setData("client.model", client);
            this.$router.push({
                name: "editClient",
                params: { clientId: client.id },
            });

        },
        deleteClient(clientId) {
            popupService.togglePopup();
            popupService.togglePopupMessage("Delete Action", "This action will delete the selected client and all the associated projects,assessments,artifacts,users and scoring records with it. Are you sure you want to delete?",
                (action) => {
                    if (action === 'yes') {
                        this.deleteSelectedClient(clientId)
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            )
        },

        ProjectListClick(client) {
            sharedService.setData("client.model", client);
            this.$router.push({ name: "projectList", params: { clientId: client.id } });
        },
        async fetchClientList() {
            try {
                const response = await clientListService.fetchClientList();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientListResponse = response.data.data;
                    this.clientList = [...this.clientListResponse];
                    if (this.clientList.length <= 0) {
                        this.showclientListTableContent = false
                        this.showDefaultContent = true
                        this.showEmptyTableContent = "No client to view, continue to create client";
                    } else {
                        this.showclientListTableContent = true
                        this.showDefaultContent = false
                        this.showEmptyTableContent = "";
                    }
                    this.updateDisplayedRecords();

                } else {
                    showToast("Failed to fetch clients", "error", 'validationerror');
                }
            } catch (err) {
                console.error('error in controller--', err);

            }
        },

        searchByClientName() {
            if (this.searchText) {
                this.displayedClientList = this.clientList.filter(client =>
                    client.clientName.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedClientList.length === 0) {
                    this.showEmptyTableContent = "No clients available for searched text";
                } else {
                    this.showEmptyTableContent = "";
                }
            } else {
                this.displayedClientList = this.clientList;
                this.showEmptyTableContent = "";
            }
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },


        async deleteSelectedClient(clientId) {
            try {
                const response = await clientService.deleteClient(clientId);
                if (response.data.header.responseMessage === "SUCCESS")
                    if (response.data.data === "SUCCESS") {
                        showToast("Client Deleted Successfully", 'success', 'validationsuccess');
                        popupService.togglePopup();
                        this.fetchClientList();
                    } else {
                        showToast("Failed to Delete Client", 'error', 'validationerror');
                        popupService.togglePopup();
                    }
            }
            catch (error) {
                //error.response.data.message
                console.log(error);
                showToast("Failed to Delete Client", 'error', 'validationerror');
            }
        },

        sortClient(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedClientList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedClientList, currentPage, rowsPerPage);
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },

        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.clientList,
            );

            // set pagination data from the service
            this.displayedClientList = this.paginationData.displayedRecords;

        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.clientList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },

    },
}







