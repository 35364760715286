<template>
  <div id="application">
    <router-view :key="$route.fullPath" />
  </div>
  <PopUpModule v-if="popupService.showPopup" :headerMessage="popupService.headerMessage" :message="popupService.message" @confirm="popupService.handleButtonClick('yes')" @cancel="popupService.handleButtonClick('no')"/>

  <div :class="{ 'overlay': serverRequestOn }">
  </div>
  <div class="loading-bar-spinner" :class="{ 'spinner': serverRequestOn }">
    <div class="spinner-icon"></div>
  </div>

</template>

<style scoped src="../src/style.css"></style>
<script src="./app.js"></script>