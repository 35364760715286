<template>
  <div class="container-fluid d-flex flex-column justify-content-center align-items-center ms-5 p-0 position-absolute deletePopup">
    <div class="d-flex flex-column whiteBox">
      <div class="d-flex justify-content-start align-items-center header">
        <span>{{headerMessage}}</span>
      </div>
      <div v-if="!showTextArea">
      <div class="d-flex flex-column justify-content-center align-items-center p-5">
        <span class="content">{{message}}</span>
        <a v-if="showHyperLink" href="#!" @click="redirect(passwordLink)" class="p-1">Click here</a>
      </div>
      <div class="d-flex justify-content-center p-3 margin150 buttn">
          <button class="btn btn-primary buttonStyle float-end" @click="okSelected">
            OK 
          </button>
      </div>
    </div>
    <div v-if="showTextArea">
      <div class="d-flex flex-column justify-content-center align-items-left p-4">
        <div>
          <span class="content">{{message}}</span>
          <textarea v-model="textareaContent" class="form-control mt-3 checkText" rows="3" @input="validationTextLength" readonly></textarea>
        </div>
      </div>
      <div class="p-3 buttn">
          <button class="btn btn-primary buttonStyle float-end" @click="okSelected">
            OK 
          </button>  
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./infoPopup.controller.js"></script>
<style scoped src="./infoPopup.style.css"></style>

