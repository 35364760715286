import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import AssessmentScoring from '@/app/score/AssessmentScoring.vue';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
//import artifactScoreModel from '@/app/model/artifactScore.model.js';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import { reactive, ref, toRaw } from 'vue';
import artifactService from '../ArtifactDetails/artifact.service.js';
import SharedService from '../services/shared.service.js';
import statusService from '../services/status.service.js';
import PopUpModule from "../shared/custompopup/PopUpModule.vue";
import InfoPopup from "../shared/infopopup/InfoPopup.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';
import assessmentscoreService from './assessmentscore.service.js';

export default {

    name: 'assessmentScoring',
    components: { FooterComponent, HeaderComponent, SearchAssessment, SideNav, AssessmentScoring, ToastComponent, BreadcrumbComponent, PopUpModule, InfoPopup },
    props: {},
    setup() {
        const showManualScoreError = ref({})
        const loggedInRole = SharedService.getDataFromBrowser('role');
        const loggedInUserId = SharedService.getDataFromBrowser('userId');
        const hasError = (id) => {
            return !!showManualScoreError.value[`${id}`];
        };
        return {
            toastState: toast,
            loggedInRole,
            loggedInUserId,
            hasError,
            showManualScoreError
        }
    },
    created() {
        this.ArtifactModel = SharedService.getData("artifact.model");
        // this.selectedAssessor = SharedService.getData("artifactStatus.model");
        if (this.ArtifactModel) {
            this.fetchArtifactScoring(this.ArtifactModel);
        } else {
            this.ArtifactModel = {};
        }
        if (this.loggedInRole == 'frameworkAdmin') {
            this.viewScore = true
        }
        this.routePath = '/assessmentScoring';
        this.$store.commit('addToHistory', this.$route);
        this.breadcrumbStyles = {
            width: '85.5%'
        }
        this.assessmentId = this.$route.params.assessmentId;
        if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
            this.findArtifactByAssessmentIdAndUserId(this.assessmentId);
            if (this.loggedInRole == 'reviewer') {
                this.viewScore = true
            }
        }
        if (this.loggedInRole == 'frameworkAdmin') {
            this.loadArtifactByAssessmentId(this.assessmentId);
        }
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    data() {
        return {
            artifactScoreModel: [],
            isActive: false,
            isSubCategoryActive: false,
            ArtifactModel: {},
            selectedAssessor: {},
            userAssignedArtifactList: [],
            artifactAssessorList: [],
            artifactList: [],
            assessmentName: this.$route.params.assessmentName,
            error: {},
            enableQualityCriteria: false,
            validateArtifactType: false,
            validateSelectedAssessor: false,
            validateArtifactName: false,
            enableSearchClick: false,
            inputTextError: '',
            inputText: '',
            maxlength: 100,
            assessmentId: '',
            indexValue: '',
            overAllErrorMsg: '',
            navigationClick: 'Home',
            frameworkId: '',
            validationMessageStyle: {},
            validationMessageStyleForSubmit: {},
            categoryLineHeight: [],
            subCategoryLineHeight: [],
            qcLineHeight: [],
            catIndex: '',
            questionListLinkEnd: {},
            breadcrumbStyles: {},
            artifactTypes: [],
            viewScore: false,
            routePath: '',
            showPopUpModule: false,
            showPopUpModuleReject: false,
            showAlreadyRejPopUpModule: false,
            popUpModuleStyles: {},
            toggleLabel: "Expand All",
            toggleFlag: false,
        }

    },
    methods: {
        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip() {
            this.showToolTipIndex = null;
            this.activeTooltipType = null;
        },
        downloadFile(url) {
            if (url != undefined || url != null) {
                const link = document.createElement('a');
                link.href = url;
                link.download = true;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else
            {
                showToast('Please select artifact to download the file', 'warning', 'validationwarning');
            }
        },

        getStatus(status) {
            this.status = statusService.getStatus(status);
        },

        toggleClick() {
            this.toggleFlag = !this.toggleFlag;
            if (this.toggleFlag) {
                this.toggleLabel = "Collapse All";
                this.toggleAll(this.toggleFlag);
            }
            else {
                this.toggleLabel = "Expand All";
                this.toggleAll(this.toggleFlag);
            }
        },
        toggleAll(flag) {
            for (let index = 0; index < this.artifactScoreModel.data.category.length; index++) {
                this.artifactScoreModel.data.category[index].expanded = flag;
                this.artifactScoreModel.data.category[index].active = flag;
                this.isCatActive = false;
                this.catIndex = index;
                if (this.artifactScoreModel.data.category[index].subCategory.length > 0) {
                    setTimeout(() => this.dynamicCategoryListHeight(index), 100);
                    setTimeout(() => {
                        this.$refs.subCategoryHeight.forEach((_, index) => {
                            this.dynamicSubCatListHeight(index)
                        }, 50)
                    })
                }
                for (let subCatIndex = 0; subCatIndex < this.artifactScoreModel.data.category[index].subCategory.length; subCatIndex++) {
                    this.artifactScoreModel.data.category[index].subCategory[subCatIndex].expanded = flag;
                    this.artifactScoreModel.data.category[index].subCategory[subCatIndex].active = flag;
                    this.isSubCategoryActive = false;
                    setTimeout(() => this.dynamicSubCatListHeight(subCatIndex), 100);
                    setTimeout(() => {
                        this.$refs.qcHeight.forEach((_, subCatIndex) => {
                            this.dynamicQcListHeight(subCatIndex)
                        }, 50)
                    })
                }
            }
        },


        toggleCategory(item, index, flag) {
            if (item.expanded === undefined) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            } else if (item.expanded === true) {
                item.expanded = false;
                item.active = false;
                this.isActive = flag;
            } else if (item.expanded === false) {
                item.expanded = true;
                item.active = true;
                this.isActive = false;
            }
            this.catIndex = index
            setTimeout(() => this.dynamicCategoryListHeight(index), 100);
            setTimeout(() => {
                this.$refs.subCategoryHeight.forEach((_, index) => {
                    this.dynamicSubCatListHeight(index)
                }, 50)
            })
        },
        togglesSubCategory(subCategoryitem, index, flag) {
            if (subCategoryitem.expanded === undefined) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            } else if (subCategoryitem.expanded === true) {
                subCategoryitem.expanded = false;
                subCategoryitem.active = false;
                this.isSubCategoryActive = flag;
            } else if (subCategoryitem.expanded === false) {
                subCategoryitem.expanded = true;
                subCategoryitem.active = true;
                this.isSubCategoryActive = false;
            }
            setTimeout(() => this.dynamicSubCatListHeight(index), 100);
            setTimeout(() => {
                this.$refs.qcHeight.forEach((_, index) => {
                    this.dynamicQcListHeight(index)
                }, 50)
            })
        },
        dynamicSubCatListHeight(index) {
            const divSubCategoryElement = this.$refs.subCategoryHeight[index]
            if (divSubCategoryElement) {
                this.subCategoryLineHeight[index] = divSubCategoryElement.clientHeight + 30
            }
            this.dynamicCategoryListHeight(this.catIndex)
        },
        dynamicCategoryListHeight(index) {
            const divCategoryElement = this.$refs.categoryHeight[index]
            if (divCategoryElement) {
                this.categoryLineHeight[index] = divCategoryElement.clientHeight + 30
            }

        },
        dynamicQcListHeight(index) {
            const divQcElement = this.$refs.qcHeight[index]
            if (divQcElement) {
                this.qcLineHeight[index] = divQcElement.clientHeight + 30
                this.questionListLinkEnd = {
                    margin: '10px 0px 0px 15px'
                }
            }

        },
        homeClick() {
            if (this.navigationClick == "Home") {
                this.$router.push("/landingPage");
            }
        },

        loadArtifactNameByType(event) {
            this.validateArtifactType = true;
            this.artifactListByType = [];
            if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
                for (let artifact of toRaw(this.userAssignedArtifactList)) {
                    if (event.target.value === artifact.artifactType) {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
            } else {
                for (let artifact of toRaw(this.artifactList)) {
                    if (event.target.value === artifact.artifactType) {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
            }
            this.error.artifactTypeError = "";
            this.error.artifactNameError = "";
        },

        setArtifactFlags(artifact) {
            this.enableQualityCriteria = false;
            if (this.loggedInRole == "frameworkAdmin" || this.loggedInRole == "reviewer") {
                this.findAssessorsByArtifactId(artifact.id)
                this.selectedAssessor = {};

            }
            if (this.loggedInRole == 'assessor') {
                this.ArtifactModel.assessorId = this.loggedInUserId;
                this.findArtifactStatusByAssessorId(artifact.id, this.loggedInUserId);
            }
            this.validateArtifactName = true;
            this.error.artifactNameError = "";

        },
        setSelectedAssessor(assessor) {
            this.enableQualityCriteria = false;
            this.validateSelectedAssessor = true;
            this.selectedAssessor.assessorId = assessor.userId;
            this.selectedAssessor.artifactStatus = assessor.artifactStatus;
            this.selectedAssessor.overAllComments = assessor.overAllComments;
        },


        searchClick() {
            this.filterValidation();
            //Api call to fetch the artifact score
            if (this.enableSearchClick) {
                this.ArtifactModel.assessorId = this.selectedAssessor.assessorId;
                this.fetchArtifactScoring(this.ArtifactModel);
            }
            if (this.loggedInRole == 'assessor') {
                if (this.selectedAssessor.artifactStatus == 'submitted') {
                    showToast('Artifact already submitted for approval', 'success', 'validationsuccess')
                } else if (this.selectedAssessor.artifactStatus == 'rejected') {
                    this.confirmAlreadyRejApproval();
                } else if (this.selectedAssessor.artifactStatus == 'approved') {
                    showToast('Artifact already approved', 'success', 'validationsuccess')
                }
            }
            if (this.loggedInRole == 'reviewer') {
                if (this.selectedAssessor.artifactStatus == 'approved') {
                    showToast('Artifact already approved', 'success', 'validationsuccess')
                }
            }
        },

        filterValidation() {

            if (!this.validateArtifactName) {
                this.error.artifactNameError = "Please select Artifact Name";
                this.error.artifactTypeError = "";
                event.preventDefault();
            }
            else if (this.loggedInRole !== 'assessor' && this.validateArtifactName && !this.validateSelectedAssessor) {
                this.error.artifactNameError = "";
                this.error.artifactAssessorError = "Please select an Assessor"
                this.error.artifactTypeError = "";
            }
            else {
                this.enableSearchClick = true;
                this.error.artifactAssessorError = "";
                event.preventDefault();
            }
        },

        checkLength() {
            if (this.inputText.length > this.maxlength) {
                this.inputTextError = "Text exceeds the maximum limit allowed"
            }
        },

        checkScore(qc) {
            if (qc.score > 100) {
                qc.scoreNotValid = true
                this.validationMessageStyle = {
                    marginTop: '3.2%'
                }
                this.manualScoreError = "Enter values between 0 to 100"
            } else if (qc.score < 0) {
                qc.scoreNotValid = true
                this.validationMessageStyle = {
                    marginTop: '3.2%'
                }
                this.manualScoreError = "Enter values between 0 to 100"
            } else if ((qc.score >= 0) && (qc.score <= 100)) {
                qc.scoreNotValid = false
            }
        },
        closeScore() {
            this.$router.push("/searchAssessment");
        },

        artifactDashboard(id) {
            if (this.selectedAssessor.artifactStatus != 'draft') {
                this.$router.push({ name: "artifactFrameworkDashboard", params: { artifactId: id } });
            } else {
                showToast('Score the artifact to view dashboard', 'error', 'validationerror');
            }
        },
        scrollToTop() {
            this.validationMessageStyle = {
                marginTop: '3.2%'
            }
            window.scrollTo(0, 0);
        },
        confirmSubmitApproval() {
            this.showPopUpModule = true;
            this.popUpModuleStyles = {
                headerMessage: "Submit for Approval",
                message: "Please verify the scores before submission.Once submitted it cannot be changed. Are you sure want to submit the artifact for approval?.",
                buttonAction: "submit"
            };
        },
        confirmRejectApproval() {
            this.showPopUpModuleReject = true;
            this.popUpModuleStyles = {
                headerMessage: "Reject Artifact",
                message: "Are you sure want to reject the artifact?. Please provide a detailed reason for rejection",
                showTextArea: true,
                buttonAction: "reject"
            };
        },
        confirmApproval() {
            this.showPopUpModule = true;
            this.popUpModuleStyles = {
                headerMessage: "Approve Artifact",
                message: "Please verify the scores before approving. Are you sure want to approve this artifact ?",
                buttonAction: "approve"

            };
        },
        confirmAdminUpdateScore() {
            this.showPopUpModule = true;
            this.popUpModuleStyles = {
                headerMessage: "Save Score",
                message: "Please verify the scores before saving. Are you sure want to save the score ?",
                buttonAction: "updateScoreByAdmin"
            };
            this.scrollToTop();
        },
        confirmAlreadyRejApproval() {
            this.showAlreadyRejPopUpModule = true;
        },
        popUpConfirmButton(action) {
            if (action === 'submit') {
                this.submitArtifact();
            } else if (action === 'approve') {
                this.approveArtifact();
            }
            else if (action === 'updateScoreByAdmin') {
                this.saveScoreAPI();
            }
        },
        closePopUp() {
            this.showPopUpModule = false;
            this.showPopUpModuleReject = false;
            this.showAlreadyRejPopUpModule = false;
        },
        async submitForApproval() {
            if (this.selectedAssessor.artifactStatus == 'submitted') {
                this.throwSubmitError = false
                showToast('Artifact already submitted for Review', 'success', 'validationsuccess')

            } else {
                var validToSaveScore = this.validateSubmitApproval();
                if (validToSaveScore) {
                    this.saveScoreAPI();
                    this.overAllErrorMsg = '';
                    this.confirmSubmitApproval();
                } else {
                    this.scrollToTop();
                }
                // this.validToSaveScore = true;
            }
        },
        rejectApproval() {
            if (this.selectedAssessor.artifactStatus == 'rejected') {
                this.throwSubmitError = false
                showToast('Artifact rejected', 'success', 'validationsuccess')

            } else {
                this.saveScoreAPI();
                this.confirmRejectApproval();
            }
        },
        artifactApproval() {
            if (this.selectedAssessor.artifactStatus == 'approved') {
                this.throwSubmitError = false
                showToast('Artifact Approved', 'success', 'validationsuccess')

            } else {
                this.saveScoreAPI();
                this.confirmApproval();
            }
        },
        updatedScore() {
            //  let artifactScoreData = {};
            const qcScoreList = [];
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        // if (qc.score != null && (qc.existingScoreId === null)) {
                        if (qc.score >= 0 && qc.score <= 100) {
                            qcScoreList.push({
                                _id: qc.existingScoreId,
                                // assessmentId: this.assessmentId,
                                artifactId: this.ArtifactModel.id,
                                frameworkId: category.parentId,
                                categoryId: category._id,
                                subCategoryId: subCategory._id,
                                qcId: qc._id,
                                score: qc.score,
                                assessorComment: qc.assessorComment,
                                reviewerComment: qc.reviewerComment,
                                assessorId: qc.assessorId
                            });
                        }
                    });
                });
            });
            return qcScoreList;
        },
        async submitArtifact() {
            this.closePopUp();
            const artifactObj = {
                artifactId: this.ArtifactModel.id,
                assessorId: this.selectedAssessor.assessorId,
                artifactStatus: "submitted"
            }
            const response = await artifactService.updateArtifactStatus(artifactObj);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.selectedAssessor = response.data.data;
                //   this.getStatus(this.selectedAssessor.artifactStatus);
                showToast('Artifact submitted for Approval', 'success', 'validationsuccess')
            } else {
                showToast('Failed to Submit the Artifact for Approval', 'error', 'validationerror')
            }
        },
        async rejectArtifact(overAllComments) {
            var validToReject = true;
            if (overAllComments.length > 250) {
                showToast('Rejection reason cannot be exceed 250 characters', 'error', 'validationerror');
                validToReject = false;
            }
            if (overAllComments.length < 10) {
                showToast('Rejection reason is too short.', 'error', 'validationerror');
                validToReject = false;
            }
            if (overAllComments.length == 0) {
                showToast('Provide the reason for rejecting the artifact', 'error', 'validationerror');
                validToReject = false;
            }
            if (validToReject) {
                this.closePopUp();
                const artifactObj = {
                    artifactId: this.ArtifactModel.id,
                    assessorId: this.selectedAssessor.assessorId,
                    overAllComments: overAllComments,
                    artifactStatus: "rejected",
                }
                const response = await artifactService.updateArtifactStatus(artifactObj);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.selectedAssessor = response.data.data;
                    // SharedService.setData("artifact.model", this.ArtifactModel);
                    //  this.getStatus(this.selectedAssessor.artifactStatus);
                    showToast('Artifact Rejected', 'success', 'validationsuccess')
                } else {
                    showToast('Failed to Reject the Artifact', 'error', 'validationerror')
                }
            }

        },

        async approveArtifact() {
            this.closePopUp();
            const artifactObj = {
                artifactId: this.ArtifactModel.id,
                assessorId: this.selectedAssessor.assessorId,
                artifactStatus: "approved"
            }
            const response = await artifactService.updateArtifactStatus(artifactObj);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.selectedAssessor = response.data.data;
                // SharedService.setData("artifact.model", this.ArtifactModel);
                //   this.getStatus(this.selectedAssessor.artifactStatus);
                showToast('Artifact Approved', 'success', 'validationsuccess')
            } else {
                showToast('Failed to Approve the Artifact', 'error', 'validationerror')
            }
        },

        validateScores() {
            var validScore = true;
            this.overAllErrorMsg = ""
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        if (qc.score > 100 || qc.score < 0) {
                            this.validationMessageStyleForSubmit = {
                                margin: '0% 0% 0% 1% !important',
                                fontSize: '15px !important'
                            }
                            validScore = false;
                            this.overAllErrorMsg = "Please enter scores between 0 to 100";
                        }
                    })
                })
            })
            return validScore;
        },
        validateSubmitApproval() {
            var validToSaveScore = true;
            this.artifactScoreModel.data.category.forEach(category => {
                category.subCategory.forEach(subCategory => {
                    subCategory.qc.forEach(qc => {
                        if ((qc.score > 100) || (qc.score < 0) || (qc.score === '') || (qc.score === undefined) || (qc.score === null)) {
                            this.throwSubmitError = true
                            this.validationMessageStyleForSubmit = {
                                margin: '0% 0% 0% 1% !important',
                                fontSize: '15px !important'
                            }
                            validToSaveScore = false;
                            this.overAllErrorMsg = "Please enter valid Scores between 0 to 100 for all QC"

                        }
                    })
                })
            })
            return validToSaveScore;
        },
        saveForLater() {
            var validScore = this.validateScores();
            if (validScore) {
                this.overAllErrorMsg = '';
                const saveResponse = this.saveScoreAPI();
                if (saveResponse) {
                    showToast('Score Saved successfully', 'success', 'validationsuccess')
                }
            } else {
                this.scrollToTop();
            }
        },
        async saveScoreAPI() {
            this.closePopUp();
            const qcScoreList = this.updatedScore();
            const reqObject = {
                saveScoreList: qcScoreList,
                artifactStatus: this.selectedAssessor.artifactStatus
            }


            const response = await assessmentService.saveArtifactScore(reqObject);
            if (response.status === 200) {
                this.scrollToTop();
                const response = await assessmentscoreService.findArtifactStatusByAssessorId(this.ArtifactModel.id, this.selectedAssessor.assessorId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.selectedAssessor.assessorId = response.data.data.assessorId;
                    this.selectedAssessor.artifactStatus = response.data.data.artifactStatus;
                    // SharedService.setData("artifact.model", this.ArtifactModel);
                    return true;

                } else {
                    showToast('Failed to fetch artifact assessor details', 'error', 'validationerror')
                }
                // this.fetchArtifactScoring(this.ArtifactModel);

            } else {
                showToast("Failed to save score", 'error', 'validationerror')
            }

        },

        async updateScoreByAdmin() {
            var validToSaveScore = this.validateSubmitApproval();
            if (validToSaveScore) {
                this.confirmAdminUpdateScore();
            }
        },

        async fetchArtifactScoring(artifact) {
            SharedService.setData("artifact.model", artifact);
            this.frameworkId = artifact.frameworkId;
            this.assessmentId = artifact.assessmentId;
            if (this.selectedAssessor.artifactStatus == 'submitted' || this.selectedAssessor.artifactStatus == 'approved') {
                this.viewScore = true;
            }
            assessmentService.fetchArtifactScoring(artifact.id, artifact.assessorId).then(response => {
                if (response.status === 200) {
                    this.artifactScoreModel = response.data
                    /*   if (this.selectedAssessor.artifactStatus === "draft") {
                           this.artifactScoreModel.data.category.forEach(category => {
                               category.subCategory.forEach(subCategory => {
                                   subCategory.qc.forEach(qc => {
                                       if (qc.score == 0) {
                                           qc.score = null; // or undefined
                                       }
                                   });
                               });
                           });
                       }*/
                    this.enableQualityCriteria = true;
                    this.toggleLabel = "Collapse All";
                    this.toggleFlag = true;
                    this.toggleAll(this.toggleFlag);
                } else {
                    showToast('Failed to fetch Scores for selected Artifact', 'error', 'validationerror')
                }
            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast('Failed to fetch Scores for selected Artifact', 'error', 'validationerror')
                });
        },


        async findArtifactStatusByAssessorId(artifactId, assessorId) {

            const response = await assessmentscoreService.findArtifactStatusByAssessorId(artifactId, assessorId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.selectedAssessor.assessorId = response.data.data.assessorId;
                this.selectedAssessor.artifactStatus = response.data.data.artifactStatus;
                this.selectedAssessor.overAllComments = response.data.data.overAllComments;
            } else {
                showToast('Failed to fetch artifact assessor details', 'error', 'validationerror')
            }
        },


        async findAssessorsByArtifactId(artifactId) {
            const response = await assessmentscoreService.findAssessorsByArtifactId(artifactId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactAssessorList = response.data.data
                if (this.artifactAssessorList.length <= 0) {
                    showToast('No assessor available for selected artifact', 'warning', 'validationwarning')
                }
            } else {
                showToast('Failed to fetch artifact assessor details', 'error', 'validationerror')
            }
        },

        async findArtifactByAssessmentIdAndUserId(assessmentId) {
            const userId = SharedService.getDataFromBrowser("userId");
            const response = await assessmentService.findArtifactByAssessmentIdAndUserId(assessmentId, userId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data
                //this.artifactTypes = [...new Set(this.userAssignedArtifactList.map(artifact => artifact.artifactType))]
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        async loadArtifactByAssessmentId(assessmentId) {
            const response = await artifactService.findArtifactListByAssessmentId(
                assessmentId
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data;
                this.artifactTypes = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                // sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Failed to fetch Artifacts for the selected Assessment", "error", 'validationerror');
            }
        },
    },
}