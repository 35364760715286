import SearchAssessment from '@/app/AssessementDetails/SearchAssessment.vue';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import AssessmentModel from '@/app/model/assessment.model.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import Paginate from "vuejs-paginate-next";
import listAssessmentData from '../../assets/data/listAssessment.json';
import AssessmentScoring from '../score/AssessmentScoring.vue';
import { default as SharedService, default as sharedService } from '../services/shared.service.js';
import { sharedVariable } from '../services/sharedData.js';
import popupService from '../shared/custompopup/popupModule.service';
import { showToast, toast } from '../shared/toast/toast.controller.js';
/* import { DataService } from '../services/service'; */
/* import LarvelVuePagination from 'laravel-vue-pagination'; */
import sortingService from '@/app/services/sort.service.js';
import { paginationService } from '../services/pagination.service';
import statusService from '../services/status.service';

export default {
    name: "searchAssessment",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        BreadcrumbComponent,
        SideNav,
        AssessmentScoring,
        paginate: Paginate,
        ToastComponent
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    setup() {
        const loggedInRole = SharedService.getDataFromBrowser("role");
        sharedVariable.value = window.location.pathname;
        return {
            toastState: toast,
            loggedInRole,
        };
    },

    created() {
        if (this.loggedInRole == "assessor" || this.loggedInRole == "reviewer") {
            this.displaySearchFilter();
        } else {
            this.ClientModel = sharedService.getData("client.model");
            this.ProjectModel = sharedService.getData("project.model");
            if (!this.ClientModel || !this.ProjectModel) {
                this.ProjectModel = {}
                this.ClientModel = {}
                this.onLoadClientName();
            } else {
                this.searchClick(this.ProjectModel.id);
            }

        }
        this.breadcrumbStyles = {
            width: "83.75%",
        };
        // this.showEmptyTableContent = true
        this.$store.commit("clearHistory");
        this.$store.commit("addToHistory", this.$route);

    },
    data() {
        return {
            assessments: listAssessmentData,
            page: 10,
            showDisplayContent: true,
            showDefaultContent: true,
            showFirstSearchBox: true,
            showSecondSearchBox: false,
            clientList: [],
            projectList: [],
            assessmentList: [],
            assessmentListforAssessor: [],
            userAssignedArtifactList: [],
            clientName: "",
            projectName: "",
            AssessmentModel,
            ProjectModel: {},
            ClientModel: {},
            ArifactModel: {},
            projectId: "",
            userId: "",
            role: "",
            assessmentId: "",
            showAssessmentSearchFilter: true,
            showAddAssessmentButton: true,
            searchAssessmentList: {},
            validateClientName: false,
            validateProjectName: false,
            clientError: "",
            projectError: "",
            navigationClick: "Home",
            breadcrumbStyles: {},
            assessmentListStyleAssessor: {},
            showBreadcrumbs: false,
            showTableContent: "",
            showAssessorTableContent: false,
            showToolTipIndex: null,
            activeTooltipType: null,
            createAssessmentResponse: {},
            newAssessmentID: "",
            iconStyleAssessor: {},
            spaceDivAssessor: {},
            sortOrder: {
                assessmentName: "common",
                startdate: "common",
                artifactCount: "common",
                status: "common"

            },
            assessmentListResponse: [],
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            selectedPage: 1,
            displayedAssessmentList: [],
            currentPage: 1,
            rowsPerPage: 10,
            totalPages: 0,
            paginationData: {
            },
            selectedStatus: "All",
        };
    },
    computed: {
        filteredassessmentList() {
            return this.displayedAssessmentList
        }
    },


    methods: {
        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            console.log(index);
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        isLastRow(index) {
            return index === this.filteredassessmentList.length - 1 && this.filteredassessmentList.length > 1;
        },
        clickCallback(pageNum) {
            console.log(pageNum);
        },
        enableSearchClick(projectId) {
            this.validateSelection(projectId);
            this.clearMessage = {
                visibility: "visible !important",
            };
            // this.fetchAssessmentListByProjectIdAndStatus(projectId);
        },
        validateSelection(projectId) {
            if (this.validateClientName == true && this.validateProjectName == true) {
                this.searchClick(projectId);
                this.clientError = "";
                this.projectError = "";
                event.preventDefault();
            } else if (this.validateClientName == true && this.validateProjectName == !true) {
                this.projectError = "Please select Project";
                this.clientError = "";
                event.preventDefault();
            } else if (this.validateClientName == !true && this.validateProjectName == true) {
                this.clientError = "Please select Client";
                event.preventDefault();
            } else {
                this.clientError = "Please select Client";
                event.preventDefault();
            }
        },
        dateSelected(date) {
            const dateObj = new Date(date);
            return dateObj.toLocaleDateString("en-us", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
        searchClick(projectId) {
            this.showFirstSearchBox = false;
            this.showDisplayContent = false;
            this.showSecondSearchBox = true;
            this.showDefaultContent = false;
            this.fetchAssessmentListByProjectIdAndStatus(projectId);
            // event.preventDefault();
        },

        getStatus(status) {
            return statusService.getStatus(status)

        },
        reFilterClick() {
            this.ProjectModel = {};
            this.ClientModel = {};
            this.assessmentList = [];
            this.displayedAssessmentList = [];
            this.validateClientName = false;
            this.validateProjectName = false;
            this.showFirstSearchBox = true;
            this.showSecondSearchBox = false;
            this.showDisplayContent = true;
            this.showDefaultContent = true;
            this.onLoadClientName();
            event.preventDefault();
            this.clearMessage = {
                visibility: "hidden !important",
            };
            this.selectedStatus = 'All';
        },
        addNewAssessmentClick() {
            this.$router.push("/createAssessment");
            // this.$router.push({ name: "createAssessment", params: { assessmentId: ' ' } });
        },
        editAssessment(assessment) {

            sharedService.setData("assessment.model", assessment);
            if (this.loggedInRole == "frameworkAdmin") {
                this.$router.push({
                    name: "editAssessment",
                    params: { assessmentId: assessment.id },
                });
            }
            if (this.loggedInRole == "assessor" || this.loggedInRole == "reviewer") {
                this.$router.push({
                    name: "viewAssessment",
                    params: { assessmentId: assessment.id },
                });

            }
        },
        deleteAssessmentClick(assessment) {

            if (assessment.assessmentStatus != 'draft') {
                showToast("Assessment already started cannot be deleted", 'error', 'validationerror');
            }
            else {
                popupService.togglePopup();
                popupService.togglePopupMessage("Delete Action", "This action will delete the selected assessment and all the associated artifacts,users and scoring records with it. Are you sure you want to delete?",
                    (action) => {
                        if (action === 'yes') {
                            this.deleteSelectedAssessment(assessment.id);
                        }
                        else {
                            popupService.togglePopup();
                        }
                    }
                );
            }
        },

        addArtifact(assessment) {
            sharedService.setData("assessment.model", assessment);
            this.$router.push({
                name: "artifactList",
                params: { assessmentId: assessment.id },
            });
        },


        scoreIconClick(assessment) {
            if (assessment.artifactCount > 0) {
                this.ArtifactModel = sharedService.clearData('artifact.model');
                this.$router.push({
                    name: "assessmentScoring",
                    params: { assessmentId: assessment.id, assessmentName: assessment.assessmentName },
                });
            } else {
                showToast("Please add artifact to score", "error", 'validationerror');
            }
        },

        dashboardIconClick(assessment) {
            if (assessment.artifactCount > 0) {
                this.$router.push({ name: "assessmentDashboard", params: { assessmentId: assessment.id } });
                sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Please add artifact, Score it and view the dashboard", "error", 'validationerror');
            }
        },
        selectedProjectId() {
            this.validateProjectName = true;
            this.projectError = "";
            // this.projectId = event.target.value
        },
        displaySearchFilter() {
            //const role = SharedService.getDataFromBrowser("role");

            this.showAssessmentSearchFilter = false;
            this.showAddAssessmentButton = false;
            this.showDisplayContent = false;
            this.showDefaultContent = true;
            this.searchAssessmentList = {
                justifyContent: "flex-start !important",
            };
            this.showBreadcrumbs = true;
            const mediaQuery = window.matchMedia('( (min-width:1025px) and (max-width:1280px))')
            if(mediaQuery.matches){
                this.assessmentListStyleAssessor = {
                    marginTop: "1rem !important",
                };
            }

            this.findAssessmenListByUserId();

        },
        async onLoadClientName() {
            try {
                const response = await assessmentService.fetchClient();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientList = response.data.data.filter(client => client.isActive === 'Y');
                } else {
                    showToast("Failed to fetch Clients", "error", 'validationerror');
                }
            }
            catch (error) {
                console.log('client error', error);
                showToast("Failed to fetch Clients", "error", 'validationerror');
            }
        },
        async fetchAssessmentListByProjectIdAndStatus(projectId) {
            const response = await assessmentService.fetchAssessmentByProjectIdAndStatus(
                projectId, this.selectedStatus
            );

            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;
                if (this.assessmentList.length <= 0 && this.selectedStatus == 'All') {
                    this.showTableContent = 'No Assessments to view for selected project, continue adding Assessments';
                }
                else if ((this.assessmentList.length <= 0) && (this.selectedStatus == 'completed' || this.selectedStatus == 'inprogress' || this.selectedStatus == 'draft')) {
                    this.showTableContent = 'No Assessments available for filtered status';
                }
                else {
                    this.showTableContent = "";
                }
                this.updateDisplayedRecords();
                sharedService.setData("client.model", this.ClientModel);
                sharedService.setData("project.model", this.ProjectModel);
                sharedService.setData("assessment.model", this.AssessmentModel);
            } else {
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
        },


        searchByAssessments() {
            if (this.searchText) {
                this.displayedAssessmentList = this.assessmentList.filter(assessment =>
                    assessment.assessmentName.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedAssessmentList.length === 0) {
                    this.showTableContent = "No Assessments available for searched text";
                } else {
                    this.showTableContent = "";
                }
            } else {
                this.displayedAssessmentList = this.assessmentList;
                this.showTableContent = "";
            }
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.assessmentList
            );

            // set pagination data from the service
            this.displayedAssessmentList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.assessmentList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
        async loadProjectByClient() {
            this.validateClientName = true;
            try {
                const response = await assessmentService.fetchProjectByClientId(this.ClientModel.id);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.projectList = response.data.data.filter(project => project.isActive === 'Y');
                    if (this.projectList.length <= 0) {
                        showToast("No projects available for the selected client", "warning", "validationwarning");
                    }
                } else {
                    showToast("Failed to fetch Projects", "error", 'validationerror');
                }
                this.clientError = "";
                this.projectError = "";
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
        },
        async findAssessmenListByUserId() {
            const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))');
            if (mediaQuery.matches) {
                this.spaceDivAssessor = {
                    justifyContent: 'start !important',
                    marginLeft: '-0.55em'
                }
/*                 this.iconStyleAssessor = {
                    width: '3.5em !important'
                } */
            }
            this.userId = SharedService.getDataFromBrowser("userId");
            this.role = SharedService.getDataFromBrowser("role");
            console.log("ID is--->", this.userId);

            assessmentService.fetchAssessmentByUserId(this.userId, this.role).then(response => {
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.assessmentList = response.data.data;
                    if (this.assessmentList.length === 0) {
                        this.showAssessorTableContent = true;

                    } else {
                        this.showAssessorTableContent = false;
                        this.updateDisplayedRecords();
                    }
                } else {
                    showToast("Failed to fetch user assigned assessment", "error", 'validationerror');
                }

            })
                .catch(err => {
                    // Handle errors
                    console.error('error in controller--', err);
                    showToast('Failed to fetch user assigned assessment', 'error', 'validationerror')
                });
        },
        async deleteSelectedAssessment(assessmentId) {
            const response = await assessmentService.deleteAssessment(assessmentId);
            //if (response.data.header.responseMessage === "SUCCESS")
            if (response.data.data === "SUCCESS") {
                showToast("Assessment Deleted Successfully", 'success', 'validationsuccess');
                this.fetchAssessmentListByProjectIdAndStatus(this.ProjectModel.id);
                popupService.togglePopup();
            } else {
                showToast("Failed to Delete Assessment", 'error', 'validationerror');
                popupService.togglePopup();
            }
        },

        sortAssessment(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedAssessmentList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedAssessmentList, currentPage, rowsPerPage)
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
    },
};