import artifactService from '@/app/ArtifactDetails/artifact.service.js';
import UserList from '@/app/ArtifactDetails/ArtifactList.vue';
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import { paginationService } from '@/app/services/pagination.service.js';
import sortingService from '@/app/services/sort.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import popupService from '@/app/shared/custompopup/popupModule.service.js';
import FooterComponent from '@/app/shared/footer/FooterComponent.vue';
import HeaderComponent from '@/app/shared/header/HeaderComponent.vue';
import SideNav from '@/app/shared/side-navigation/SideNav.vue';
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import userService from '@/app/UserDetails/user.service.js';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue-3';
import { reactive, toRaw } from 'vue';
import sharedService from "../../services/shared.service.js";
import { showToast } from '../../shared/toast/toast.controller.js';


export default {
    name: "assignUserList",
    components: {
        FooterComponent,
        HeaderComponent,
        UserList,
        SideNav,
        SweetModal,
        SweetModalTab,
        BreadcrumbComponent,
        ToastComponent
    },
    props: {
        columns: {
            type: Array,
            required: true,
        },
    },
    created() {
        // this.$store.commit('addToHistory', this.$route.fullPath);
        const macScreen = window.matchMedia('((min-width:1441px) and (max-width:1600px))');
        if(macScreen.matches){
            this.breadcrumbStyles = {
                width: '89%',
            }
        }
        else{
            this.breadcrumbStyles = {
                width: '87%'
            }
        }

        this.assessmentId = this.$route.params.assessmentId;
        this.AssessmentModel = sharedService.getData('assessment.model');
        if (!this.AssessmentModel) {
            this.fetchAssessment(this.assessmentId);
        }
        this.assessmentUserList(this.assessmentId);
        this.fetchArtifactList(this.assessmentId);
        this.fetchAllUser();
    },
    data() {
        return {
            userList: {},
            page: 10,
            dialog: null,
            assessmentId: '',
            artifactListByType: [],
            artifactTypes: [],
            filteredArtifactList: [],
            UserModel: {},
            fetchUserList: {},
            ArtifactModel: {},
            AssessmentModel: {},
            breadcrumbStyles: {},
            showDisplayContent: false,
            errors: {},
            showToolTipIndex: null,
            activeTooltipType: null,
            showDisplayMessage: "",
            tableBodyStyles: {},
            sortOrder: {
                emailId: "common",
                firstName: "common",
                artifactType: "common",
                artifactName: "common",
                role: "common"

            },
            userListResponse: [],
            searchText: '',
            isSearchVisible: false,
            selectedView: 10,
            viewDetails: [10, 20, 30],
            currentPage: 1,
            selectedPage: 1,
            rowsPerPage: 10,
            displayedUserList: [],
            totalRecords: 0,
            totalPages: 0,
            paginationData: {},
        };
    },

    computed: {
        filtereduserList() {
            return this.displayedUserList;

        },
    },
    methods: {

        showToolTip(index, type) {
            this.showToolTipIndex = index;
            this.activeTooltipType = type;
        },
        hideToolTip(index, type) {
            this.showToolTipIndex = null;
            this.activeTooltipType = type;
        },
        isLastRow(index) {
            return index === this.filtereduserList.length - 1 && this.filtereduserList.length > 1;
        },
        assessmentList() {
            if (this.assessmentId) {
                this.$router.push({ name: "editAssessment", params: { assessmentId: this.assessmentId, assessmentName: this.assessmentName } });
            } else {
                showToast("Assessment is not created", 'warning', 'validationwarning');
            }
        },
        artifactList() {
            if (this.assessmentId) {
                this.$router.push({ name: "artifactList", params: { assessmentId: this.assessmentId } });
            } else {
                showToast("Assessment is not created", 'warning', 'validationwarning');
            }
        },
        closeUserPopUp() {
            this.$refs.modal.close();
            this.assessmentUserList(this.assessmentId);
        },
        artifactTypeValidation() {
            this.artifactListByType = [];
            if (this.UserModel.accessType == undefined) {
                this.errors.artifactType = 'Please select a valid Artifact Type';
            } else {
                this.errors.artifactType = '';
                event.preventDefault();
                for (let artifact of toRaw(this.filteredArtifactList)) {
                    if (this.UserModel.accessType === artifact.artifactType && artifact.artifactStatus !== 'completed') {
                        this.artifactListByType.push(reactive(artifact));
                    }
                }
                if (this.artifactListByType.length === 0) {
                    showToast('No Artifact Available for this selected type', 'warning', 'validationwarning');
                }
            }
        },
        artifactNameValidation() {
            this.UserModel.accessTypeId = this.ArtifactModel.id;
            this.UserModel.frameworkId = this.ArtifactModel.frameworkId;
            if (this.UserModel.accessTypeId == undefined) {
                this.errors.artifactName = "Please select a valid Artifact";
            } else {
                this.errors.artifactName = '';
                event.preventDefault();
            }
        },
        assignUser() {
            this.UserModel = {};
            this.ArtifactModel = {};
            this.errors = {};
            this.$refs.modal.open();
        },


        userValidation() {
            if (this.UserModel.userId == undefined) {
                this.errors.email = "Please select a valid Email";
            } else {
                this.errors.email = "";
                this.UserModel.role = undefined;
                this.UserModel.accessType = undefined;
                this.ArtifactModel = {};
                event.preventDefault();
            }
        },
        userRoleValidation() {
            this.filteredArtifactList = [];
            if (this.UserModel.role == undefined) {
                this.errors.role = "Please select a valid Role";
            } else {
                this.errors.role = "";
                //build unique artifact list based on role
                if (this.UserModel.role == 'reviewer') {
                    let reviewerUser = this.userList.map((user) => {
                        if (user.role == 'reviewer') return user.artifactId;
                    })

                    let artifactUser = this.userList.map((user) => {
                        if (user.role == 'assessor' && this.UserModel.userId == user.userId) return user.artifactId;
                    })

                    for (let artifact of toRaw(this.assessmentArtifactList)) {
                        let index = reviewerUser.indexOf(artifact.id);
                        let artifactIndex = artifactUser.indexOf(artifact.id);
                        if (index < 0 && artifactIndex < 0) {
                            this.filteredArtifactList.push(artifact)
                        }
                    }
                }
                else if (this.UserModel.role == 'assessor') {
                    let asessorUser = this.userList.map((user) => {
                        if (user.role == 'assessor' && this.UserModel.userId == user.userId) return user.artifactId;
                    })
                    let artifactUser = this.userList.map((user) => {
                        if (user.role == 'reviewer' && this.UserModel.userId == user.userId) return user.artifactId;
                    })
                    for (let artifact of toRaw(this.assessmentArtifactList)) {
                        let index = asessorUser.indexOf(artifact.id);
                        let artifactIndex = artifactUser.indexOf(artifact.id);
                        if (index < 0 && artifactIndex < 0) {
                            this.filteredArtifactList.push(artifact)
                        }
                    }
                }
                else {
                    for (let artifact of toRaw(this.assessmentArtifactList)) {
                        this.filteredArtifactList.push(reactive(artifact));
                    }
                }

                this.UserModel.accessType = undefined;
                this.ArtifactModel = {};
                event.preventDefault();
            }
        },
        saveUser() {
            if (this.UserModel.userId == undefined) {
                this.userValidation();
            } else if (this.UserModel.role == undefined) {
                this.userRoleValidation();
            }
            else if (this.UserModel.accessType == undefined) {
                this.artifactTypeValidation();
            } else if (this.ArtifactModel.id == undefined) {
                this.artifactNameValidation();
            } else {
                this.UserModel.isPrimary = 'N';
                this.UserModel.assessmentId = this.ArtifactModel.assessmentId;
                this.AssignUserToArtifact();
            }
            event.preventDefault();

        },
        deleteUserClick(artifactId, userId, role) {
            popupService.togglePopup();
            popupService.togglePopupMessage("Delete Action", "This action will delete the selected user and all the associated scoring records with it. Are you sure you want to delete?",
                (action) => {
                    if (action === 'yes') {
                        this.deleteSelectedUser(artifactId, userId, role);
                    }
                    else {
                        popupService.togglePopup();
                    }
                }
            );
        },

        closeButtonClick() {
            this.$router.push("/searchAssessment");
        },
        validateUserRole(role) {
            if (role == "assessor") {
                return (this.role = "Assessor");
            } else {
                return (this.role = "Reviewer");
            }
        },

        async fetchAssessment(assessmentId) {
            try {
                const response = await assessmentService.fetchAssessmentById(assessmentId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.AssessmentModel = response.data.data;
                    sharedService.setData('assessment.model', this.AssessmentModel);
                } else {
                    showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
                }
            }
            catch (error) {
                console.log(error);
                showToast('Failed to fetch Assessment Details', 'error', 'validationerror');
            }
        },

        async AssignUserToArtifact() {
            const response = await userService.assignUserToArtifact(this.UserModel);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userResponse = response.data.data;
                // sharedService.setData('user.model', this.userResponse)
                this.closeUserPopUp()
            } else {
                showToast("Failed to Assign User to Artifact", 'error', 'validationerror');
            }
        },
        async assessmentUserList(assessmentId) {
            const response = await userService.fetchUserListByAssessmentId(assessmentId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.userList = response.data.data
                if (this.userList.length <= 0) {
                    this.showDisplayMessage = "Assign Users to view the list";
                    this.tableBodyStyles = {
                        display: 'flex !important',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }
                    this.updateDisplayedRecords();
                }
                else {
                    this.showDisplayMessage = "";
                    this.tableBodyStyles = {
                        display: 'block !important',
                    }
                    this.updateDisplayedRecords();
                }
            } else {
                showToast('Failed to User List for this Assessment', 'error', 'validationerror')
            }
        },

        searchByUserName() {
            if (this.searchText) {
                this.displayedUserList = this.userList.filter(user =>
                    user.emailId.toLowerCase().includes(this.searchText.toLowerCase())
                );
                if (this.displayedUserList.length === 0) {
                    this.showDisplayMessage = "No users available for searched text";
                } else {
                    this.showDisplayMessage = "";
                }
            } else {
                this.displayedUserList = this.userList;
                this.showDisplayMessage = "";
            }
        },

        async fetchArtifactList(assessmentId) {
            const response = await artifactService.fetchArtifactListByAssessmentId(assessmentId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentArtifactList = response.data.data
                this.artifactTypes = [...new Set(this.assessmentArtifactList.map(artifact => artifact.artifactType))]
            } else {
                showToast('Failed to fetch ArtifactList For this Assessment', 'error', 'validationerror')
            }

        },
        async fetchAllUser() {
            const response = await userService.fetchUserList();
            if (response.data.header.responseMessage === "SUCCESS") {
                this.fetchUserList = response.data.data.filter(user => user.isActive === 'Y');
            }
        },
        async deleteSelectedUser(artifactId, userId, role) {
            const response = await userService.deleteAssignedUser(artifactId, userId, role);
            if (response.data.data === "SUCCESS") {
                showToast("User Deleted Successfully", 'success', 'validationsuccess');
                this.assessmentUserList(this.assessmentId);

            } else {
                showToast("Failed to Delete User", 'error', 'validationerror');
            }
            popupService.togglePopup();
        },
        truncateEmail(emailId) {
            if (emailId.length > 25) {
                return emailId.slice(0, 24) + '...';
            }
            return emailId;
        },

        sortEmailId(column, currentPage, rowsPerPage) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = "common";
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            this.displayedUserList = sortingService.getSortColumn(column, this.sortOrder[column], this.displayedUserList, currentPage, rowsPerPage);
        },

        toggleSearch() {
            this.isSearchVisible = !this.isSearchVisible;
        },
        updateDisplayedRecords(column) {
            for (let key in this.sortOrder) {
                if (key !== column) {
                    this.sortOrder[key] = 'common';
                }
            }
            this.sortOrder[column] = this.sortOrder[column] === 'ascending' ? 'descending' : 'ascending';
            // use paginationservice to handle pagination logic
            this.paginationData = paginationService.updateDisplayRecords(
                this.selectedPage,
                this.rowsPerPage,
                this.userList
            );

            // set pagination data from the service
            this.displayedUserList = this.paginationData.displayedRecords;
        },
        viewTo() {
            this.rowsPerPage = parseInt(this.selectedView);
            paginationService.viewTo(this.rowsPerPage, this.userList);
            this.selectedPage = 1;
            this.updateDisplayedRecords();
        },
    },

};