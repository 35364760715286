//import AssessmentModel from "../model/assessment.model";
class SharedService {
    constructor() {
        this.models = {};
        //this.assessmentObj = new AssessmentModel();
        this.serverRequestOn = false;
    }

    storeDataInBrowser(key, data) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }
    getDataFromBrowser(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }
    getDataFromBrowserAsJson(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }
    removeDataFromBrowser(key) {
            sessionStorage.removeItem(key);
        }
        // setAssessmentModel(assessment) {

    //     this.assessmentObj.id = assessment.id;
    //     this.assessmentObj.assessmentDescription = assessment.assessmentDescription;
    //     this.assessmentObj.assessmentName = assessment.assessmentName;
    //     this.assessmentObj.assessmentStatus = assessment.assessmentStatus;
    //     this.assessmentObj.assessmentStartDate = assessment.assessmentStartDate;
    //     this.assessmentObj.projectId = assessment.projectId;

    //     return this.assessmentObj;
    // }
    // getAssessmentModel() {
    //     return this.assessmentObj;

    //   }

    createModel(modelType) {
        if (!this.models[modelType]) {
            const ModelClass = require(`../model/${modelType}`).default;
            this.models[modelType] = new ModelClass();
        }
        return this.models[modelType];
    }

    setData(modelType, value) {
        const model = this.createModel(modelType);
        // Assuming your model has a property 'value'
        model.value = value;
    }

    getData(modelType) {
        const model = this.createModel(modelType);
        // Assuming your model has a property 'value'
        return model.value;
    }
    clearData(modelType) {
        const model = this.createModel(modelType);
        return model.value = null;
    }

    setSpinner(isVisible){
        this.serverRequestOn = isVisible;
    }

    getSpinner(){
        return this.serverRequestOn;
    }
}
export default new SharedService();