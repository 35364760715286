<template>
  <div
    class="container-fluid d-flex flex-column justify-content-center align-items-center ms-5 p-0 position-absolute deletePopup">
    <div class="overlay">

    </div>
    <div class="d-flex flex-column whiteBox">
      <div class="d-flex justify-content-start align-items-center header">
        <span>{{ headerMessage }}</span>
      </div>
      <div v-if="!showTextArea" class="d-flex flex-column justify-content-center align-items-center p-5">
        <span class="content">{{ message }}</span>
      </div>
      <div v-if="showTextArea" class="d-flex flex-column justify-content-center align-items-center p-4">
        <div>
          <span class="msgcontent">{{ message }}</span>
          <textarea v-model="rejectionReason" class="form-control mt-3 checkText" rows="3"
            placeholder="Enter rejection reason here..." @input="validationTextLength"></textarea>
          <div class="text text-muted mt-1">{{ rejectionReason.length }}/250</div>
        </div>
      </div>

      <div class="d-flex justify-content-end p-3 margin150 bttn">
        <button class="btn btn-primary buttonStyle float-end" @click="confirmSelected">
          Yes
        </button>
        <button class="btn btn-primary buttonStyle float-end ms-3" @click="cancelSelected">
          No
        </button>
      </div>
    </div>
  </div>
</template>
<script src="./popupModule.controller.js"></script>
<style scoped src="./popupModule.style.css"></style>

