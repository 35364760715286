<template>
  <HeaderComponent />
  <!-- <ToastComponent :visible="toastState.visible" :message="toastState.message" :toastType="toastState.type"></ToastComponent> -->
  <FooterComponent />
  <SideNav />
  <div class="container-fluid p-0">
    <div class="row login-bg m-0">
      <div class="rightContainer d-flex justify-content-end p-0 m-0">
        <div class="bg-right"></div>
        <div class="row mb-0 d-flex flex-column justify-content-start align-items-center userListAssessmentBox">
          <div class="row p-0 d-flex justify-content-start align-items-center mb-3 breadcrumbsResponsive"
            :style="breadcrumbStyles">
            <BreadcrumbComponent />
          </div>
          <div class="d-flex flex-column justify-content-between align-items-center z-3 position-relative">
            <div class="navigationImageBox d-flex justify-content-between ms-5">
              <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation"
                v-on:click="assessmentList()">
                <div class="assessImage">

                </div>
                <div class="navigationImageText">Assessment</div>
              </div>
              <span class="horizontalLine mt-5 mb-5"> </span>
              <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation"
                v-on:click="artifactList()">
                <div class="artifactImage">

                </div>
                <div class="navigationImageText">Artifacts</div>
              </div>
              <span class="horizontalLine mt-5 mb-5"></span>
              <div class="d-flex flex-column justify-content-end align-items-center imagesForNavigation">
                <div class="userImage">
                  <span class="dottedCircle"></span>
                </div>
                <div class="navigationImageText">Assign User</div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mt-4 p-0 userResponsiveDiv">
            <!--  <div class="d-flex justify-content-between align-items-center ms-5 p-0 userListBox"> -->
            <div class="headingTextBox ms-5 d-flex align-items-center">
              User List -{{ AssessmentModel?.assessmentName }}
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div v-if="userList.length > 0" class="searchApplication">
                <input v-if="isSearchVisible" v-model="searchText" type="text" name="searchText" autocomplete="off"
                  placeholder="Search by UserName" class="form-control customSearch" @input="searchByUserName()">
              </div>
              <span v-if="userList.length > 0" @click="toggleSearch" class="customSearchIcon"></span>
              <select v-if="userList.length > 0" class="page-selector ms-4" v-model="selectedView" @change="viewTo" :disabled="userList.length < 10">
                <option selected disabled>view</option>
                <option v-for="view in viewDetails" :key="view" :value="view">{{ view }}</option>
              </select>
              <button type="search" class="btn btn-primary assignUserButton" v-on:click="assignUser()">
                Assign User
              </button>
            </div>
            <!--   </div> -->
          </div>

          <!--  Popup to Assign User -->

          <sweet-modal ref="modal" width="1100px" :blocking="true" id="assignUserModal">
            <div class="d-flex flex-column">
              <div class="popupHeader">
                <span>Assign user to Artifact</span>
              </div>
              <div class="popupContent">
                <form class="d-flex flex-column justify-content-center p-0">
                  <div class="d-flex justify-content-center ms-5 marginTopResponsive p-0">
                    <div class="col-6 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="clientNameInput"
                          class="form-label createAssessmentLabel">Email</label>*</span>
                      <div class="dropdownSelect dropdownResponsive">
                        <select v-model="UserModel.userId" id="accessType" class="dynamicDropdown"
                          @change="userValidation">
                          <option disabled selected :value="undefined" class="dropdownOption">Select User</option>
                          <option v-for="user in fetchUserList" :key="user.id" :value="user.id" selected>
                            {{ user.emailId }}
                          </option>
                        </select>
                      </div>
                      <div v-if="errors.email" class="validationMessage">{{ errors.email }}</div>
                    </div>
                    <div class="col-6 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="clientNameInput"
                          class="form-label createAssessmentLabel">User Role</label>*</span>
                      <div class="dropdownSelect dropdownResponsive">
                        <select v-model="UserModel.role" id="userRole" class="dynamicDropdown"
                          @change="userRoleValidation">
                          <option selected value="undefined" disabled>Select User Role</option>
                          <option selected class="optionClass" value="assessor">Assessor</option>
                          <option selected class="optionClass" value="reviewer">Reviewer</option>
                        </select>
                      </div>
                      <div v-if="errors.role" class="validationMessage">{{ errors.role }}</div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center ms-5 mt-5 marginTopResponsive p-0">
                    <div class="col-6 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="clientNameInput"
                          class="form-label createAssessmentLabel">Artifact Type</label>*</span>
                      <div class="dropdownSelect dropdownResponsive">
                        <select v-model="UserModel.accessType" id="accessType" class="dynamicDropdown"
                          :disabled="!this.UserModel.role" @change="artifactTypeValidation">
                          <option disabled selected :value="undefined" class="dropdownOption">Select the type of
                            Artifact</option>
                          <option v-for="artifact in artifactTypes" :key="artifact.id" :value="artifact" selected>
                            {{ artifact }}
                          </option>
                        </select>
                      </div>
                      <div v-if="errors.artifactType" class="validationMessage">{{ errors.artifactType }}</div>
                    </div>
                    <div class="col-6 d-flex flex-column">
                      <span class="mandatoryFieldInput"><label for="clientNameInput"
                          class="form-label createAssessmentLabel">Artifact Name</label>*</span>
                      <div class="dropdownSelect dropdownResponsive">
                        <select v-model="ArtifactModel" id="accessType" class="dynamicDropdown"
                          :disabled="!this.UserModel.accessType" @change="artifactNameValidation">
                          <option disabled selected :value="{}" class="dropdownOption">Select the Artifact</option>
                          <option v-for="artifact in artifactListByType" :key="artifact.id" :value="artifact" selected>
                            {{ artifact.artifactName }}
                          </option>
                        </select>
                      </div>
                      <div v-if="errors.artifactName" class="validationMessage">{{ errors.artifactName }}</div>
                    </div>
                  </div>
                  <div class="row d-flex justify-content-between mt-5 p-0 marginTopResponsive">
                    <div class="d-flex justify-content-end align-items-center">
                      <button type="submit" class="btn btn-primary saveLaterButton float-end ms-2"
                        v-on:click="saveUser()">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </sweet-modal>

          <div class="row mx-auto d-flex justify-content-center align-items-center" style="width: 97%;">
            <div class="assessmentSearchTable ms-5 mt-3 p-0">
              <table>
                <thead>
                  <tr>
                    <th style="border-radius: 10px 0px 0px 0px; ">EmailID
                      <span
                        :class="sortOrder.emailId === 'ascending' ? 'icon-ascending' : sortOrder.emailId === 'descending' ? 'icon-descending' : 'icon-common'"
                        @click="sortEmailId('emailId')"></span>
                    </th>
                    <th>Name
                      <span
                        :class="sortOrder.firstName === 'ascending' ? 'icon-ascending' : sortOrder.firstName === 'descending' ? 'icon-descending' : 'icon-common'"
                        @click="sortEmailId('firstName')"></span>
                    </th>
                    <th>Artifact Type
                      <span
                        :class="sortOrder.artifactType === 'ascending' ? 'icon-ascending' : sortOrder.artifactType === 'descending' ? 'icon-descending' : 'icon-common'"
                        @click="sortEmailId('artifactType')"></span>
                    </th>
                    <th style="width:55%">Artifact Name
                      <span
                        :class="sortOrder.artifactName === 'ascending' ? 'icon-ascending' : sortOrder.artifactName === 'descending' ? 'icon-descending' : 'icon-common'"
                        @click="sortEmailId('artifactName')"></span>
                    </th>
                    <th>Role
                      <span
                        :class="sortOrder.role === 'ascending' ? 'icon-ascending' : sortOrder.role === 'descending' ? 'icon-descending' : 'icon-common'"
                        @click="sortEmailId('role')"></span>
                    </th>
                    <th style="border-radius: 0px 10px 0px 0px;">Action</th>
                  </tr>
                </thead>
                <tbody :style="tableBodyStyles">
                  <tr v-for="(user, index) in filtereduserList" :key="index">
                    <td>
                      <span class="userEmail" v-if="user.emailId.length > 25" :title="user.emailId">{{
                        truncateEmail(user.emailId) }} </span>
                      <span v-else>{{ user.emailId }}</span>
                    </td>
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.artifactType }}</td>
                    <td style="width:55%">{{ user.artifactName }}</td>
                    <!--  <td>{{ user.role }}</td> -->
                    <td :class="validateUserRole(user.role)">
                      {{ role }}
                    </td>
                    <td>
                      <div class="d-flex">
                        <span class="deleteIcon" v-on:click="deleteUserClick(user.artifactId, user.userId, user.role)"
                          @mouseover="showToolTip(index, 'delete')" @mouseleave="hideToolTip(index, 'delete')">
                          <div v-if="showToolTipIndex === index && activeTooltipType === 'delete'" class="toolTip">
                            <div :class="['tooltiptext', isLastRow(index) ? 'up' : 'down']">
                              Delete
                            </div>
                          </div>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <!-- <div class="d-flex justify-content-center align-items-center displayContent"> -->
                  <span v-if="showDisplayMessage" class="d-flex justify-content-center align-items-center mt-5 mb-5 displayContent">{{ showDisplayMessage }}</span>
                  <!-- </div>  -->
                </tbody>
              </table>
            </div>

          </div>

          <div v-if="userList.length > 0" class="d-flex justify-content-end align-items-center mt-3 paginationBox">
            <span class="float-right pagination-detail">Jump to</span>
            <span class="me-1 ms-1 seperator"></span>
            <label class="page">Page</label>
            <div class="dropdown-wrapper">
              <select class="page-selector-pagination" v-model="selectedPage" @change="updateDisplayedRecords">
                <option v-for="page in paginationData.totalPages" :key="page" :value="page">{{ page }}</option>
              </select>
            </div>
            <span class="ms-2 me-2 seperator"></span>
            <span class="pagination-detail me-1">
              Showing {{ paginationData.startRecordIndex }} to {{ paginationData.endRecordIndex }} of {{
                paginationData.totalRecords }}
            </span>
          </div>
          <div class="d-flex justify-content-end mt-3 p-0 closeButtonResponsive">
            <button type="submit" class="btn btn-primary saveLaterButton float-end ms-2"
              v-on:click="closeButtonClick()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./assign-user-list.controller.js"></script>
<style scoped src="./assign-user-list.style.css"></style>
<style>
@media only screen and (min-width:1025px) and (max-width:1280px) {
  .sweet-modal .sweet-box-actions {
    top: 0px !important;
    right: 0px !important;
  }

  .sweet-modal .dropdownSelect::after {
    font-size: 12px !important;
  }

  .sweet-modal {
    width: 765px !important;
  }

  #assignUserModal .popupContent .marginTopResponsive {
    margin-left: 2rem !important;
  }

}

@media only screen and (min-width:1281px) and (max-width:1440px) {
  #assignUserModal .sweet-modal {
    width: 750px !important;
  }

  .popupContent .createAssessmentLabel {
    margin-left: -0.1em !important;
  }

  #assignUserModal .sweet-modal .sweet-content .sweet-content-content .popupContent {
    padding: 1em 1em 1em 0em !important;
  }

  .sweet-modal .sweet-box-actions {
    top: 1px !important;
    right: 0px;
  }
}

/* SweetModal CSS */
.sweet-modal {
  border-radius: 10px;
}

.sweet-modal.is-alert .sweet-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.sweet-modal .sweet-content {
  padding: 0px 0px !important;
}

.sweet-modal .sweet-box-actions {
  top: 6px;
  right: 0px;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  display: block !important;
  color: #ffffff !important;
}

.sweet-modal .sweet-box-actions .sweet-action-close:hover {
  background: transparent !important;
  color: #fff !important;
}

.sweet-modal .sweet-box-actions .sweet-action-close {
  height: 35px !important;
}

#assignUserModal .sweet-modal .dropdownSelect .dynamicDropdown {
  width: 66%;
}

.sweet-modal .dropdownSelect .dynamicDropdown {
  width: 71%;
  border: none;
  border-bottom: 1px solid #000000;
  cursor: pointer;
  font: normal normal normal 1vw/3vh Ubuntu;
}

.sweet-modal .dropdownSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.sweet-modal .dropdownSelect::after {
  content: "▼";
  transform: translateY(-50%);
  font-size: 16px;
  color: #000000;
  margin-left: -1em;
  pointer-events: none;
  cursor: pointer;
}

.sweet-modal-overlay {
  z-index: 15 !important;
}
</style>