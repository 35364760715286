import SearchAssessment from "@/app/AssessementDetails/SearchAssessment.vue";
import assessmentService from '@/app/AssessementDetails/assessment.service.js';
import dashboardService from '@/app/Dashboard/dashboard.service.js';
import BreadcrumbComponent from '@/app/shared/breadcrumbs/BreadcrumbComponent.vue';
import FooterComponent from "@/app/shared/footer/FooterComponent.vue";
import HeaderComponent from "@/app/shared/header/HeaderComponent.vue";
import SideNav from "@/app/shared/side-navigation/SideNav.vue";
import ToastComponent from '@/app/shared/toast/ToastComponent.vue';
import jsonData from "@/assets/data/cat-score.json";
import Charts from "chart.js/auto";
import Chart from "chartjs-gauge";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { SweetModal, SweetModalTab } from 'sweet-modal-vue-3';
import artifactService from "../ArtifactDetails/artifact.service.js";
import clientService from "../client/client.service.js";
import projectService from "../project/project.service.js";
import assessmentscoreService from '../score/assessmentscore.service.js';
import sharedService from '../services/shared.service.js';
import { sharedVariable } from "../services/sharedData.js";
import statusService from "../services/status.service.js";
import PopUpModule from "../shared/custompopup/PopUpModule.vue";
import { showToast, toast } from '../shared/toast/toast.controller.js';


export default {
    name: "FrameworkDashboard",
    components: {
        FooterComponent,
        HeaderComponent,
        SearchAssessment,
        SweetModal,
        SweetModalTab,
        ToastComponent,
        SideNav,
        PopUpModule,
        BreadcrumbComponent
    },
    setup() {
        const loggedInRole = sharedService.getDataFromBrowser("role");
        const loggedInUserId = sharedService.getDataFromBrowser('userId');
        sharedVariable.value = window.location.pathname;
        return {
            loggedInRole,
            loggedInUserId,
            toastState: toast,
        };
    },

    data() {
        return {
            toastState: toast,
            dynamicMessage: '',
            headerMessage: '',
            popUpModuleStyles: {},
            popupAction: '',
            searchFilter: {
                display: true,
                showDetails: false,
                scoringScreen: false
            },
            showPopUpModule: false,
            showSemiCircleChart: true,
            whiteBoxVisible: true,
            showDonutChart: true,
            selectedAssessor: {},
            showSpiderChart: true,
            showBarChart: true,
            hideSearchSection: true,
            gaugeValue: 0,
            chartData: jsonData,
            filteredScoreLabel: [],
            backgroundColor: '',
            donut: {
                backgroundColor: ['#e86959', '#AC7DD1', '#eea616', '#26ce96', '#60B4F9', '#FF64A3'],
            },
            gradientColor: [],
            hoverColorforGradient: [],
            filteredBackgroundColor: [],
            displayChart: false,
            artifactAssessorList: [],
            clientList: [],
            projectList: [],
            assessmentList: [],
            artifactTypeList: [],
            artifactListByType: [],
            artifactList: [],
            ProjectModel: {},
            ClientModel: {},
            AssessmentModel: {},
            ArtifactModel: {},
            Score: undefined,
            errors: {
                assessmentName: "",
                artifactName: "",
                artifactAssessor: ""
            },
            breadcrumbStyles: {},
            routePath: '',
            artifactStatus: '',
            subCatDoughnutChart: null,
            catDonutChart: null,
            showForSubcatChart: false,
            subCategoryScoreHeading: "SubCategory Score",
            showLockUnlockIcon: false
        };
    },
    created() {
        // this.$store.commit('clearHistory');
        this.$store.commit('addToHistory', this.$route);
        const mediaQuery = window.matchMedia('((min-width:1025px) and (max-width:1280px))')
        const mediaQueryMidScreen = window.matchMedia('((min-width:1281px) and (max-width:1440px))');
        if (mediaQueryMidScreen.matches) {
            this.breadcrumbStyles = {
                width: "83.2%",
            };
        }
        else if(mediaQuery.matches){
            this.breadcrumbStyles = {
                width: '85.25%'
            }
        }
        else{
            this.breadcrumbStyles = {
                width: '82%'
            }
        }

        if ((window.innerWidth > 1025) && (window.innerWidth <= 1280)) {
            this.subCatChartStyles = {
                width: '315px !important',
                height: '250px !important'
            }
            this.subCategoryDonutChart = {
                width: '250px !important',
                height: '250px !important'
            }
        }

        if ((window.innerWidth > 1281) && (window.innerWidth <= 1440)) {
            this.subCatChartStyles = {
                width: '475px !important',
                height: '300px !important'
            }
            this.chartWrapper = {
                width: '475px !important',
                height: '330px !important'
            }
            this.subCategoryDonutChart = {
                width: '300px !important',
                height: '300px !important'
            }
        }
        if (this.$route.params.artifactId) {
            this.ArtifactModel = sharedService.getData("artifact.model");
            // this.selectedAssessor = sharedService.getData("selectedAssessor.model");
            this.fetchArtifactScoreList(this.$route.params.artifactId, this.ArtifactModel.assessorId);
            this.searchFilter.display = false;
            this.searchFilter.scoringScreen = true;
            this.routePath = '/artifactFrameworkDashboard'
            this.displayChart = true;
            this.fetchAssessment(this.ArtifactModel.assessmentId);
        } else if (this.$route.params.assessmentId) {
            this.routePath = '/frameworkDashboard'
            this.assessmentId = this.$route.params.assessmentId;
            this.searchFilter.showDetails = true;
            //this.displayChart = false;
            this.AssessmentModel = sharedService.getData("assessment.model");
            if (!this.AssessmentModel) {
                this.AssessmentModel={};
                this.fetchAssessment(this.assessmentId);
            }
            this.ClientModel = sharedService.getData("client.model");
            this.ProjectModel = sharedService.getData("project.model");
            this.selectedAssessment(this.assessmentId);
        } else {
            if (this.loggedInRole == 'frameworkAdmin') {
                this.onLoadClientName();
            } else {
                this.findAssessmentByUser();
            }
        }
    },
    beforeUnmount() {
        this.$store.commit("removeFromHistory", this.routePath);
    },
    methods: {
        buttonClick(artifactId) {
            // this.whiteBoxVisible = true;
            // this.showSemiCircleChart = true;
            // this.showDonutChart = true;
            // this.showSpiderChart = true;
            // this.showBarChart = true;
            // this.displayChart = true;
            // this.fetchArtifactScoreList(artifactId);
            if (this.loggedInRole == 'frameworkAdmin' && this.ClientModel.id == undefined) {
                this.validateClient();
            }
            else if (this.loggedInRole == 'frameworkAdmin' && this.ProjectModel.id == undefined) {
                this.selectedProject();
            }
            else if (this.AssessmentModel.id == undefined) {
                this.assessmentValidation();
            } else if (this.ArtifactModel.id == undefined) {
                this.artifactNameValidation();
            }
            else if (this.selectedAssessor.assessorId == undefined) {
                this.validateSelectedAssessor();
            }
            else if (this.status == "Draft") {
                showToast('No Score available for selected Artifact', 'error', 'validationerror');
            }
            else {
                this.displayChart = true;
                this.errors.artifactAssessor = "";
                this.fetchArtifactScoreList(artifactId, this.selectedAssessor.assessorId);
            }
            event.preventDefault();
        },

        truncateText(text, limit){
            if(!text) return "";
            return text.length > limit ? text.substring(0, limit) + "..." : text;
        },

        assessmentValidation() {
            // this.ClientModel = {};
            this.displayChart = false;
            if (this.AssessmentModel.id == undefined) {
                this.errors.assessmentName = "Please Select a Assessment";
            } else {
                this.errors.assessmentName = "";
                this.ArtifactModel = {};
                this.selectedAssessor = {};
                this.selectedAssessment(this.AssessmentModel.id);

            }
        },
        artifactNameValidation() {
            // this.ClientModel = {};
            this.displayChart = false;
            if (this.ArtifactModel.id == undefined) {
                this.errors.artifactName = "Please Select a Artifact Name";
            } else {
                this.errors.artifactName = "";
                this.selectedAssessor = {};
                if (this.loggedInRole == "frameworkAdmin" || this.loggedInRole == "reviewer") {
                    this.findAssessorsByArtifactId(this.ArtifactModel.id)
                }
                if (this.loggedInRole == 'assessor') {
                    this.ArtifactModel.assessorId = this.loggedInUserId;
                    this.findArtifactStatusByAssessorId(this.ArtifactModel.id, this.loggedInUserId);
                }
            }
        },

        getStatus(status) {
            this.status = statusService.getStatus(status);
        },

        validateSelectedAssessor() {
            this.displayChart = false;
            if (this.selectedAssessor.assessorId == undefined) {
                this.errors.artifactAssessor = "Please Select an Assessor";
            } else {
                this.errors.artifactAssessor = "";
            }

        },
        setSelectedAssessor(assessor) {
            this.displayChart = false;
            this.showLockUnlockIcon = false;
            this.selectedAssessor.assessorId = assessor.userId;
            this.selectedAssessor.artifactStatus = assessor.artifactStatus;
            this.errors.artifactAssessor = "";
        },
        backToScoringScreen() {
            this.$router.push({
                name: "assessmentScoring",
                params: { assessmentId: this.AssessmentModel.id, assessmentName: this.AssessmentModel.assessmentName },
            });
        },
        async findAssessmentByUser() {
            const userId = sharedService.getDataFromBrowser("userId");
            const role = sharedService.getDataFromBrowser("role");
            const response = await assessmentService.fetchAssessmentByUserId(userId, role)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        validateClient() {
            this.displayChart = false;
            if (this.ClientModel.id == undefined) {
                this.errors.clientName = "Please Select a Client";
            } else {
                this.errors.clientName = "";
                //this.selectedAssessment();    
                this.AssessmentModel = {};
                this.assessmentList = [];
                this.ArtifactModel = {};
                this.selectedAssessor = {};
                this.ProjectModel = {};
                this.loadProjectByClient();
            }
        },
        selectedProject() {
            this.displayChart = false;
            if (this.ProjectModel.id == undefined) {
                this.errors.projectName = "Please Select a Project";
            } else {
                this.errors.projectName = "";
                this.assessmentList = [];
                this.artifactList = [];
                this.AssessmentModel = {};
                this.ArtifactModel = {};
                this.selectedAssessor = {};
                this.loadAssessmentByProjectId(this.ProjectModel);
            }
            // this.projectId = event.target.value
        },
        selectedAssessment(assessmentId) {
            if (this.loggedInRole == 'assessor' || this.loggedInRole == 'reviewer') {
                this.findArtifactByAssessmentIdAndUserId(assessmentId);
            } else {
                this.loadArtifactByAssessmentId(assessmentId);
            }
        },

        lockArtifactPopup() {
            this.headerMessage = "Lock Artifact";
            this.dynamicMessage = "Are you sure to lock & finalize this artifact?";
            this.popupAction = "lock";
            this.showPopUpModule = true;
        },
        unlockArtifactPopup() {
            this.headerMessage = "UnLock Artifact";
            this.popupAction = "unlock";
            this.dynamicMessage = "Are you sure want to unlock the artifact?";
            this.showPopUpModule = true;
        },
        closePopup() {
            this.showPopUpModule = false;
        },

        popupConfirm(action) {
            if (action == 'lock') {
                this.finalizeArtifactAPI();
            }
            if (action == 'unlock') {
                this.unlockArtifactAPI();
            }

        },
        async finalizeArtifactAPI() {
            const artifactObj = {
                artifactId: this.ArtifactModel.id,
                assessorId: this.selectedAssessor.assessorId,
                artifactStatus: "finalized"
            }
            const response = await artifactService.updateOverAllArtifactStatus(artifactObj);
            if (response.data.header.responseMessage === "SUCCESS") {
                showToast('Artifact Locked Successfully', 'success', 'validationsuccess')
                this.closePopup();
                this.selectedAssessor.artifactStatus = "finalized";
            } else {
                showToast('Failed to Lock Artifact', 'error', 'validationerror')
            }


        },
        async unlockArtifactAPI() {
            const artifactObj = {
                artifactId: this.ArtifactModel.id,
                assessorId: this.selectedAssessor.assessorId,
                artifactStatus: "inprogress"
            }
            const response = await artifactService.updateOverAllArtifactStatus(artifactObj);
            if (response.data.header.responseMessage === "SUCCESS") {
                showToast('Artifact UnLocked', 'success', 'validationsuccess')
                this.closePopup();
                this.selectedAssessor.artifactStatus = "approved";
            } else {
                showToast('Failed to UnLock Artifact', 'error', 'validationerror')
            }


        },
        async onLoadClientName() {
            try {
                const response = await assessmentService.fetchClient();
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.clientList = response.data.data.filter(client => client.isActive === 'Y');
                    this.inputReadOnly = {
                        outline: "none !important",
                        border: "none !important",
                    };
                } else {
                    showToast("Failed to fetch Clients", "error", "validationerror");
                }
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Clients", "error", "validationerror");
            }
        },
        async fetchAssessment(assessmentId) {
            try {
                const response = await assessmentService.fetchAssessmentById(assessmentId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.AssessmentModel = response.data.data;
                    sharedService.setData('assessment.model', this.AssessmentModel);
                    if(!this.ClientModel || !this.ProjectModel){
                        this.fetchProjectById();
                    }
                } else {
                    showToast('Failed to fetch Assessment Details', 'error', 'validationerror')
                }
            }
            catch (error) {
                console.log(error);
                showToast('Failed to fetch Assessment Details', 'error', 'validationerror');
            }
        },
        async fetchProjectById() {
            try {
                const response = await projectService.fetchProjectById(this.AssessmentModel.projectId);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.ProjectModel = response.data.data;
                    sharedService.setData("project.model", this.ProjectModel);
                    const clientResponse = await clientService.fetchClientById(this.ProjectModel.clientId);
                    if (clientResponse.data.header.responseMessage === "SUCCESS") {
                        this.ClientModel = clientResponse.data.data;
                        sharedService.setData("client.model", this.ClientModel);
                    } else {
                        showToast("Failed to fetch Projects", "error", 'validationerror');
                    }
                } else {
                    showToast("Failed to fetch Projects", "error", 'validationerror');
                }
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }


        },
        async loadProjectByClient() {
            this.validateClientName = true;
            try {
                const response = await assessmentService.fetchProjectByClientId(this.ClientModel.id);
                if (response.data.header.responseMessage === "SUCCESS") {
                    this.projectList = response.data.data.filter(project => project.isActive === 'Y');
                    if (this.projectList.length <= 0) {
                        showToast("No projects available for the selected client", "warning", 'validationwarning');
                    }
                } else {
                    showToast("Failed to fetch Projects", "error", 'validationerror');
                }
                sharedService.setData("client.model", this.ClientModel);
                this.clientError = "";
                this.projectError = "";
            }
            catch (error) {
                console.log(error);
                showToast("Failed to fetch Projects", "error", 'validationerror');
            }
        },
        async loadAssessmentByProjectId(selectedProject) {
            const response = await assessmentService.fetchAssessmentByProjectId(
                selectedProject.id
            );
            if (response.data.header.responseMessage === "SUCCESS") {
                this.assessmentList = response.data.data;
                if (this.assessmentList.length <= 0) {
                    showToast("No Assessment available for this project", "warning", 'validationwarning');
                }
                sharedService.setData("project.model", selectedProject);
            } else {
                showToast("Failed to fetch Assessment for the selected Project", "error", 'validationerror');
            }
        },
        async loadArtifactByAssessmentId(assessmentId) {
            const response = await artifactService.findArtifactListByAssessmentId(assessmentId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data;
                if (this.artifactList.length <= 0) {
                    showToast('No artifact available for selected assessment', 'warning', 'validationwarning')
                }
                // this.artifactTypeList = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                //sharedService.setData("assessment.model", assessment);
            } else {
                showToast("Failed to fetch Artifacts for the selected Assessment", "error", 'validationerror');
            }
        },

        async findArtifactStatusByAssessorId(artifactId, assessorId) {

            const response = await assessmentscoreService.findArtifactStatusByAssessorId(artifactId, assessorId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.selectedAssessor.assessorId = response.data.data.assessorId;
                this.selectedAssessor.artifactStatus = response.data.data.artifactStatus;
                if (this.selectedAssessor.assessorId.length <= 0) {
                    showToast('No assessor available for selected artifact', 'warning', 'validationwarning')
                }
            } else {
                showToast('Failed to fetch artifact assessor details', 'error', 'validationerror')
            }
        },


        async findAssessorsByArtifactId(artifactId) {
            const response = await assessmentscoreService.findAssessorsByArtifactId(artifactId);
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactAssessorList = response.data.data
                if (this.artifactAssessorList.length <= 0) {
                    showToast('No assessor available for selected artifact', 'warning', 'validationwarning')
                }
            } else {
                showToast('Failed to fetch artifact assessor details', 'error', 'validationerror')
            }
        },

        async findArtifactByAssessmentIdAndUserId(assessmentId) {
            const userId = sharedService.getDataFromBrowser("userId");
            const response = await assessmentService.findArtifactByAssessmentIdAndUserId(assessmentId, userId)
            if (response.data.header.responseMessage === "SUCCESS") {
                this.artifactList = response.data.data
                if (this.artifactList.length <= 0) {
                    showToast('No artifact available for selected assessment', 'warning', 'validationwarning')
                }
                // this.artifactTypeList = [...new Set(this.artifactList.map(artifact => artifact.artifactType))]
                //sharedService.setData("assessment.model", assessment);
            } else {
                showToast('Failed to fetch Projects', 'error', 'validationerror')
            }
        },
        async fetchArtifactScoreList(artifactId, assessorId) {
            if (artifactId != '') {
                const response = await dashboardService.fetchArtifactScoreList(artifactId, assessorId)
                if (response) {
                    // if (response.data.header.responseMessage === "SUCCESS") {
                    this.Score = response.data
                    if (this.Score.rqdscore <= 0) {
                        this.displayChart = false;
                        showToast('RQD Score is zero, No dashboard available', 'error', 'validationerror')
                    }
                    else
                    this.showLockUnlockIcon = true;
                    this.createSemiDonutChart();
                    this.createDonutChart(this.Score.categoryList);
                    this.createSpiderChart(this.Score.categoryList);
                    this.createBarChart(this.Score.categoryList);



                } else {
                    showToast('No Score found ', 'error', 'validationerror')
                }
            } else {
                showToast('Failed to fetch Dashboard score', 'error', 'validationerror')
            }
        },

        async downloadPdfFile() {
            if (this.ArtifactModel.id) {
                const showLegend = document.getElementById("showLegend");
                const canvas = await html2canvas(showLegend);
                const donutChart = document.getElementById("donutChart");
                const semiDonutChart = document.getElementById("semiDonutChart");
                const spiderChartResponsive = document.getElementById("spiderChartResponsive");
                const barChart = document.getElementById("barChart");
                // const canvas = await html2canvas(semiDonutChart);

                const pdf = new jsPDF();
                const topMargin = 10;

                //adding title 
                const pageWidth = pdf.internal.pageSize.width;
                const titleText = "Assessment Dashboard";
                const fontSize = 15;
                const textWidth = pdf.getStringUnitWidth(titleText) * fontSize / pdf.internal.scaleFactor;
                const titleX = (pageWidth - textWidth) / 2;
                pdf.setFontSize(fontSize);
                pdf.setFont('helvetica', 'bold');
                pdf.text(titleText, titleX, topMargin);

                //adding line after title
                pdf.setLineWidth(0.1);
                pdf.line(19, topMargin + 2, pageWidth - 10, topMargin + 2);

                //adding neccessary data
                const assessmentName = this.AssessmentModel.assessmentName
                const clientName = this.ClientModel?.clientName || ''
                const artifactName = this.ArtifactModel.artifactName
                const artifactStatus = this.ArtifactModel.artifactStatus
                const projectName = this.ProjectModel?.projectName || ''
                const documentName = this.ArtifactModel.attachment
                const documentType = this.ArtifactModel.artifactType
                const docAttachment = this.ArtifactModel.attachmentExtn

                pdf.setFontSize(9);
                // Client Name
                pdf.setFont('helvetica', 'bold');
                pdf.text('Client Name:', 20, 21);
                pdf.setFont('helvetica', 'normal');
                pdf.text(clientName, 41, 21);

                // Assessment Name
                pdf.setFont('helvetica', 'bold');
                pdf.text('Assessment Name:', 20, 26);
                pdf.setFont('helvetica', 'normal');
                pdf.text(assessmentName, 51, 26);

                // Project Name
                pdf.setFont('helvetica', 'bold');
                pdf.text('Project Name:', 110, 20.5);
                pdf.setFont('helvetica', 'normal');
                pdf.text(projectName, 133, 20.5);

                // Artifact Name
                pdf.setFont('helvetica', 'bold');
                pdf.text('Artifact Name:', 110, 26);
                pdf.setFont('helvetica', 'normal');
                pdf.text(artifactName, 133, 26);

                // Artifact Status
                pdf.setFont('helvetica', 'bold');
                pdf.text('Artifact Status:', 20, 31);
                pdf.setFont('helvetica', 'normal');
                pdf.text(artifactStatus, 44, 31);

                // Uploaded Document Name
                pdf.setFont('helvetica', 'bold');
                pdf.text('Uploaded Document Name:', 20, 36);
                pdf.setFont('helvetica', 'normal');
                pdf.text(`${documentName}.${docAttachment}`, 63, 36);

                // Document Type
                pdf.setFont('helvetica', 'bold');
                pdf.text('Document Type:', 110, 31);
                pdf.setFont('helvetica', 'normal');
                pdf.text(documentType, 136, 31);

                //adding lables
                const gaugeValue = this.gaugeValue;
                pdf.setFontSize(14);
                pdf.text(`Overall RQD Score: ${gaugeValue}%`, 23, 111);
                pdf.setFontSize(11);
                pdf.setFont('helvetica', 'bold');
                pdf.text('RQD Score', 20, 45);
                pdf.text('Category Score', 110, 45);
                pdf.text('Subcategory Score', 20, 133);

                //creating semiDonut chart
                pdf.setLineWidth(0.1);
                pdf.line(18, 50, 100, 50);
                pdf.addImage(
                    canvas.toDataURL("image/png"),
                    "PNG",
                    12, 55, 60, 4.5
                );
                pdf.addImage(
                    semiDonutChart.toDataURL("image/png"),
                    "PNG",
                    20, 50, 60, 65
                );

                //creating Donut chart
                pdf.setLineWidth(0.1);
                pdf.line(108, 50, 200, 50);

                pdf.addImage(
                    donutChart.toDataURL("image/png"),
                    "PNG",
                    110, 50, 60, 60
                );

                //creating Spider chart
                pdf.setLineWidth(0.1);
                pdf.line(18, 139, 200, 139);
                pdf.addImage(
                    spiderChartResponsive.toDataURL("image/png"),
                    "PNG",
                    20, 145, 100, 100
                );

                //creating Bar chart
                pdf.addImage(
                    barChart.toDataURL("image/png"),
                    "PNG",
                    120, 147, 73, 57
                );
                new jsPDF('l', 'mm', 'a4');
                const pdfFileName = `${assessmentName}_dashboard.pdf`
                pdf.save(pdfFileName);
            }
            else {

                showToast('Select the artifact to export', 'error', 'validationerror')
            }
        },
        createSemiDonutChart() {
            var semiDonutChart = new Chart("semiDonutChart", {
                type: "gauge",
                data: {
                    labels: ["Develop", "Sufficient", "Optimal"],
                    datasets: [{
                        value: this.Score.rqdscore,
                        minValue: 0,
                        data: [40, 80, 100],
                        backgroundColor: ["#e86959", "#eea616", "#26ce96"],
                        borderWidth: 0,
                    },],
                },
                options: {
                    needle: {
                        radiusPercentage: 2.5,
                        widthPercentage: 4,
                        lengthPercentage: 85,
                        color: "#B2B2B2",
                    },
                    valueLabel: {
                        display: false,
                        formatter: (value) => {
                            return "$" + Math.round(value);
                        },
                        plugins: {
                            legend: {
                                display: true,
                                // labels: {
                                //     color: 'rgb(255, 99, 132)'
                                // }

                            },
                        },
                        color: "rgba(255, 255, 255, 1)",
                        backgroundColor: "rgba(0, 0, 0, 1)",
                        borderRadius: 5,
                        padding: {
                            top: 10,
                            bottom: 10,
                        },
                    },
                },
            });
            this.gaugeValue = semiDonutChart.data.datasets[0].value;
            semiDonutChart;
        },
        createDonutChart(catList) {
            const labels = catList.map(item => item.catName);
            const values = catList.map(item => item.score);
            const catID = catList.map(item => item.catId)

            if (this.catDonutChart) {
                this.catDonutChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.chart.getContext("2d");
            this.catDonutChart = new Charts(ctx, {
                type: "doughnut", // Choose the appropriate chart type
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Scores",
                        data: values,
                        /* data: [25, 25, 25, 25], */
                        backgroundColor: this.donut.backgroundColor,
                        hoverBackgroundColor: ['#e86959', '#AC7DD1', '#eea616', '#26ce96', '#60B4F9', '#FF64A3'],
                        borderColor: "#d1d1d1",
                        borderWidth: 1,
                        datalabels: {
                            display: false,
                        },
                    },],
                },
                options: {
                    rotation: 270,
                    circumference: 360,
                    cutout: "50%",
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                            align: "start",
                            labels: {
                                boxWidth: 15,
                                padding: 10,
                                font: {
                                    family: "Ubuntu",
                                    size: function () {
                                        // Dynamically calculate font size
                                        const width = window.innerWidth;
                                        if (width > 1870) return 15;
                                        if (width > 1200) return 12; // Large screens
                                        if (width > 768) return 13; // Medium screens
                                        return 11; // Small screens
                                    },
                                },
                                generateLabels: function (chart) {
                                    const data = chart.data;
                                    return data.labels.map((label, index) => ({
                                        text: `${label} - ${data.datasets[0].data[index]}%`,
                                        fillStyle: data.datasets[0].backgroundColor[index],
                                        hidden: false,
                                        lineWidth: 0,
                                    }));
                                },
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    const value = context.raw || 0;
                                    return `Score:${value}%`;
                                }
                            }
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    hover: {
                        mode: 'nearest',
                        intersect: true,
                    },
                    elements: {
                        arc: {
                            borderWidth: 2,
                            hoverBorderWidth: 20,
                            hoverBorderColor: ['rgba(232, 105, 89, 0.3)', 'rgba(172, 125, 209, 0.3)', 'rgba(238, 166, 22, 0.3)', 'rgba(38, 206, 150, 0.3)', 'rgba(66, 135, 245, 0.3)', 'rgba(139, 69, 19, 0.3)'],
                            borderRadius: 0,
                        }
                    },
                    onHover: (event, elements, chart) => {
                        if (elements.length) {
                            chart.canvas.style.cursor = 'pointer';
                            chart.canvas.style.zIndex = '2'
                        }
                        else {
                            chart.canvas.style.cursor = 'default';
                        }
                    },
                    onClick: (event, elements) => {
                        if (elements.length > 0) {
                            const clickedIndex = elements[0].index;
                            const clickedColor = this.donut.backgroundColor[clickedIndex];
                            this.clickedSegmentLabel = labels[clickedIndex];
                            this.clickedCatID = catID[clickedIndex]
                            let selectedSubCatList = [];
                            this.Score.categoryList.forEach((category) => {
                                if (category.catId == this.clickedCatID) {
                                    selectedSubCatList.push(...category.subCategoryList);
                                }
                            });
                            this.createNewChart(selectedSubCatList, elements[0].element.options.backgroundColor, labels[clickedIndex], clickedColor);
                        }
                    },
                },
            });
            if (catList.length > 0) {
                const selectedSubcatlist = this.Score.categoryList.find(category => category.catId === catID[0]).subCategoryList;
                const defaultCatName = labels[0];
                const defaultColor = this.donut.backgroundColor[0];
                this.createNewChart(selectedSubcatlist, this.donut.backgroundColor[0], defaultCatName, defaultColor);
            }
        },
        createNewChart(selectedSubCatList, selectedSubCatColor, selectedCategoryName, catNameColor) {
            this.createSubCatDonutChart(selectedSubCatList, selectedSubCatColor, selectedCategoryName, catNameColor)
        },
        calculatedGradientColor(subcatLength, baseColorRgb) {
            const gradientPercentage = 5;
            baseColorRgb = this.hexToRgb(baseColorRgb)
            const gradientColorRgb = {
                r: Math.min(baseColorRgb.r + gradientPercentage, 255),
                g: Math.min(baseColorRgb.g + gradientPercentage, 255),
                b: Math.min(baseColorRgb.b + gradientPercentage, 255),
            }
            var color = 0.0;
            for (let i = 0; i < subcatLength; i++) {
                color = color + 0.4
                this.gradientColor[i] = `rgba(${gradientColorRgb.r},${gradientColorRgb.g},${gradientColorRgb.b},${color})`;
            }
            return this.gradientColor
        },
        hexToRgb(hex) {
            const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
            let result = []
            result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16), } : null;
        },
        createSubCatDonutChart(subCatList, selectedSubCatColor, selectedCategoryName, catNameColor) {
            this.showForSubcatChart = true;

            this.subCategoryScoreHeading = `SubCategory Score - <span style="color: ${catNameColor};"> ${selectedCategoryName}</span>`;
            const subCatNameLabel = subCatList.map((item) => item.subCatName);
            const subCatScoreValues = subCatList.map((item) => item.score);
            this.gradientColor = this.calculatedGradientColor(subCatList.length, selectedSubCatColor);
            if (this.subCatDoughnutChart) {
                this.subCatDoughnutChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.subCatChart.getContext("2d");

            this.subCatDoughnutChart = new Charts(ctx, {
                type: "doughnut", // Choose the appropriate chart type
                data: {
                    labels: subCatNameLabel,
                    datasets: [{
                        label: "subCatScore",
                        data: subCatScoreValues,
                        backgroundColor: this.gradientColor,
                        hoverBackgroundColor: this.gradientColor,
                        borderColor: this.gradientColor,
                        borderWidth: 0,
                        datalabels: {
                            display: false,
                        },
                    },],
                },
                options: {
                    rotation: 270,
                    circumference: 360,
                    cutout: "50%",
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                            align: "start",
                            labels: {
                                boxWidth: 15,
                                padding: 10,
                                font: {
                                    family: "Ubuntu",
                                    size: function () {
                                        // Dynamically calculate font size
                                        const width = window.innerWidth;
                                        if (width > 1870) return 15;
                                        if (width > 1200) return 12; // Large screens
                                        if (width > 768) return 13; // Medium screens
                                        return 11; // Small screens
                                    },
                                },
                                generateLabels: function (chart) {
                                    const data = chart.data;
                                    return data.labels.map((label, index) => ({
                                        text: `${label} - ${data.datasets[0].data[index]}%`,
                                        fillStyle: data.datasets[0].backgroundColor[index],
                                        hidden: false,
                                        lineWidth: 0,
                                    }));
                                },
                            },
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    const value = context.raw || 0;
                                    return `Score:${value}%`;
                                }
                            }
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    onHover: (event, elements, chart) => {
                        if (elements.length) {
                            chart.canvas.style.cursor = 'pointer';
                        }
                        else {
                            chart.canvas.style.cursor = 'default';
                        }
                    },
                    //commented for Hover styles
                    /*                     hover: {
                                            mode: 'nearest',
                                            intersect: true,
                                        },
                                        elements: {
                                            arc: {
                                                borderWidth: 2,
                                                hoverBorderWidth: 20,
                                                hoverBorderColor: ['rgba(232, 105, 89, 0.3)', 'rgba(172, 125, 209, 0.3)', 'rgba(238, 166, 22, 0.3)', 'rgba(38, 206, 150, 0.3)'],
                                                borderRadius: 0,
                                            }
                                        },
                                        onHover: (event, elements, chart) => {
                                            if (elements.length) {
                                                chart.canvas.style.cursor = 'pointer';
                                            }
                                            else {
                                                chart.canvas.style.cursor = 'default';
                                            }
                                        }, */
                },
            })
        },
        createSpiderChart(catList) {
            let nameScoreList = [];
            catList.forEach((ele) => {
                nameScoreList.push(...ele.subCategoryList);
            });
            let subCatNameLabel = nameScoreList.map((ele) => ele.subCatName);
            let scoreLabel = nameScoreList.map((ele) => ele.score);

            let spiderchartDataFirst = 40;
            let spiderchartDataSecond = 70;
            let spiderchartDataThird = 100;

            let redDataArray = [];
            let yellowDataArray = [];
            let greenDataArray = [];

            for (let i = 0; i < scoreLabel.length; i++) {
                redDataArray[i] = spiderchartDataFirst;
                yellowDataArray[i] = spiderchartDataSecond;
                greenDataArray[i] = spiderchartDataThird;
            }
            if (this.spiderSubCatChart) {
                this.spiderSubCatChart.destroy();
            }
            //here the const variable ctx represents the 2D drawing context of an HTML5 canvas element with ID or reference "chart".
            const ctx = this.$refs.spiderChart.getContext("2d");
            this.spiderSubCatChart = new Charts(ctx, {
                type: "radar", // Choose the appropriate chart type
                data: {
                    labels: subCatNameLabel,
                    datasets: [{
                        label: "Artifact 1",
                        data: scoreLabel,
                        backgroundColor: "rgba(75,192,192,0.01)",
                        borderColor: "rgba(0,51,204,1)",
                        borderWidth: 1,

                    },
                    {
                        label: "Develop",
                        data: redDataArray,
                        backgroundColor: "#e86959",
                        borderColor: "transparent",
                        borderWidth: 1,
                        pointRadius: 0,
                        fill: true,
                    },
                    {
                        label: "Sufficient",
                        data: yellowDataArray,
                        backgroundColor: "#eea616",
                        borderColor: "transparent",
                        pointRadius: 0,
                        fill: true,
                    },
                    {
                        label: "Optimal",
                        data: greenDataArray,
                        backgroundColor: "#26ce96",
                        borderColor: "transparent",
                        pointRadius: 0,
                        fill: true,
                    },
                    ],
                },
                options: {
                    scales: {
                        r: {
                            min: 0, // MIN
                            max: 100, // MAX
                            beginAtZero: true,
                            angleLines: {
                                z: 1,
                                color: "rgba(178, 178, 178, 0.45)",
                            },
                            grid: {
                                color: "rgba(178, 178, 178, 0.45)",
                                z: 1,
                            },
                            pointLabels: {
                                font: {
                                    weight: "bold",
                                    size: 12,
                                },
                            },
                            ticks: {
                                z: 1,
                                color: "black",
                                stepSize: 10,
                                callback: (value) => {
                                    return value + "%";
                                },
                                backdropColor: "rgba(0,0,0,0)",
                            },
                        },
                    },
                    responsive: true,
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                            labels: {
                                boxWidth: 15,
                                padding: 10,
                                font: {
                                    family: "Ubuntu",
                                    size: function () {
                                        // Dynamically calculate font size
                                        const width = window.innerWidth;
                                        if (width > 1870) return 15;
                                        if (width > 1200) return 12; // Large screens
                                        if (width > 768) return 13; // Medium screens
                                        return 11; // Small screens
                                    },
                                },
                                filter: function (legendItem, ctx) {
                                    return ctx.datasets[legendItem.datasetIndex].label !== 'Artifact 1';
                                }
                            }
                        }
                    }
                },
            });
            /*          this.ctx.datasets[0].legend = false; */
        },
        createBarChart(catList) {
            let nameScoreList = [];
            this.filteredScoreLabel = [];
            this.remainingValues = [];

            catList.forEach((ele) => {
                nameScoreList.push(...ele.subCategoryList);
            });

            let subCatNameLabel = nameScoreList.map((ele) => ele.subCatName);
            let scoreLabel = nameScoreList.map((ele) => ele.score);

            // Calculate the remaining value for each score and store it
            for (let i = 0; i < scoreLabel.length; i++) {
                let remainingValue = 100 - scoreLabel[i];
                this.filteredScoreLabel.push(remainingValue);
                this.remainingValues.push(remainingValue); // Save remainingValue
            }

            // Set bar colors based on score ranges
            this.filteredBackgroundColor = [];
            for (let i = 0; i < scoreLabel.length; i++) {
                let rangeValue = scoreLabel[i];
                if (rangeValue > 0 && rangeValue <= 40) {
                    this.filteredBackgroundColor.push("#e86959");
                } else if (rangeValue > 40 && rangeValue <= 70) {
                    this.filteredBackgroundColor.push("#eea616");
                } else if (rangeValue > 70 && rangeValue <= 100) {
                    this.filteredBackgroundColor.push("#26ce96");
                }
            }

            if (this.barSubCatChart) {
                this.barSubCatChart.destroy();
            }

            const ctx = this.$refs.barChart.getContext("2d");
            this.barSubCatChart = new Charts(ctx, {
                type: "bar",
                data: {
                    labels: subCatNameLabel,
                    datasets: [
                        {
                            data: scoreLabel,
                            backgroundColor: this.filteredBackgroundColor,
                            borderRadius: 15,
                        },
                        {
                            data: this.filteredScoreLabel,
                            borderRadius: 15,
                            backgroundColor: "#B2B2B2",
                            datalabels: {
                                display: false,
                            },
                        },
                    ],
                },
                options: {
                    responsive: true,
                    layout: {
                        padding: {
                            top: 10,
                            bottom: 10,
                            left: 15,
                            right: 45,
                        },
                    },
                    indexAxis: "y",
                    scales: {
                        x: {
                            stacked: true,
                            display: false,
                            beginAtZero: true,
                            max: 100,
                            grid: {
                                display: false,
                            },
                        },
                        y: {
                            stacked: true,
                            grid: {
                                display: false,
                            },
                        },
                    },
                    plugins: {
                        datalabels: {
                            display: true,
                            color: "#000",
                            anchor: "center",
                            align: "end",
                            font: {
                                weight: "bold",
                            },
                            formatter: Math.round,
                        },
                        legend: {
                            display: false,
                            labels: {
                                font: {
                                    family: "Ubuntu",
                                    size: function () {
                                        // Dynamically calculate font size
                                        const width = window.innerWidth;
                                        if (width > 1870) return 15;
                                        if (width > 1200) return 12; // Large screens
                                        if (width > 768) return 11; // Medium screens
                                        return 11; // Small screens
                                    },
                                },
                            }
                        },
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (context) {
                                    const datasetIndex = context.datasetIndex;
                                    if (datasetIndex === 0) {
                                        return `Score: ${context.raw}%`;
                                    }
                                    else if(datasetIndex === 1){
                                        return null;
                                    } else {
                                        return null;
                                    }
                                },
                            },
                        },
                    },
                },
                plugins: [
                    {
                        afterDatasetsDraw: (chart) => {
                            const ctx = chart.ctx;
                            ctx.save();
                            const meta = chart.getDatasetMeta(1);
                            let remainingValues = this.remainingValues;
                            const scoreValue = remainingValues.map((item) => 100 - item);
                            meta.data.forEach((bar, index) => {
                                const barPosition = bar.getProps(["x", "y", "base", "width"]);
                                const x = barPosition.x + 15;
                                const y = barPosition.y + 5;

                                // Draw the remaining values
                                ctx.fillStyle = "#000"; // Black text color
                                ctx.font = "12px Ubuntu";
                                ctx.fillText(
                                    `${scoreValue[index]}%`,
                                    x,
                                    y
                                );
                            });

                            ctx.restore();
                        },
                    },
                ],
            });
        }

    },
};