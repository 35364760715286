import SharedService from '../../services/shared.service.js';
export default {

  setup() {
    const loggedInRole = SharedService.getDataFromBrowser("role");
    return {
      loggedInRole,
    };
  },
  computed: {
    activeRoute(){
      return this.$route.path;
    }
  },

  methods: {
    clickDashboard() {
      this.$store.commit("clearHistory", this.routePath);
      this.$router.push("/frameworkDashboard");
    },

    clickAssesment() {
      this.$store.commit("clearHistory", this.routePath);
      this.$router.push("/searchAssessment");
    },

    clickFramework() {
      this.$store.commit("clearHistory", this.routePath);
      this.$router.push("/frameworkList")
    },
    clickClient() {
      this.$store.commit("clearHistory", this.routePath);
      this.$router.push("/clientList")
    },
    clickUser() {
      this.$store.commit("clearHistory", this.routePath);
      this.$router.push("/user/list")
    }

  }
}