import apiEndpoint from '@/app/services/uri-constants.js';
import axios from '../shared/interceptor.js';


export default {
    async fetchClientList() {
        return axios.get(`${apiEndpoint.clientList}`)
        .catch(function (error) {
            throw error;
        });
    },

    async createClientAPI(client) {
        
        return axios.post(`${apiEndpoint.createClient}`, client)
        .catch(function (error) {
            throw error;
        });

    },
    async fetchClientById(clientId) {
        return axios.get(`${apiEndpoint.fetchClient}` +
            clientId)
        .catch(function (error) {
            throw error;
        });

    },
    // const deleteClient = 'api/client/deleteClient?clientId'
    async deleteClient(clientId) {
        return axios.delete(`${apiEndpoint.deleteClient}` + clientId)
        .catch(function (error) {
            throw error;
        });

    },

}